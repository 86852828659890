import React, { useState } from 'react';
import * as moment from 'moment';
import MobileDatePicker from 'react-mobile-datepicker';
import FloatingLabel from 'floating-label-react';
import NumberFormat from 'react-number-format';
import { FORMAT } from 'components/utils/dateOfBirthValidation';
import { Field } from 'formik';

const documentRef = typeof document !== `undefined` ? document : null;

const CustomDatePicker = ({
  name,
  placeholderText,
  floating = true,
  disabled = false,
}) => {
  const [visible, setVisibility] = useState(false);

  const handleClick = () => {
    window.scrollTo(0, 0);
    setVisibility(true);
    if (documentRef) documentRef.body.classList.add('overflow-hidden');
  };

  const handleCancel = () => {
    setVisibility(false);
    if (documentRef) documentRef.body.classList.remove('overflow-hidden');
  };

  const handleSelect = (date, setFieldValue, name) => {
    setVisibility(false);
    setFieldValue(name, moment(date).format(FORMAT));
    if (documentRef) documentRef.body.classList.remove('overflow-hidden');
  };

  const monthMap = {
    '1': 'Jan',
    '2': 'Feb',
    '3': 'Mar',
    '4': 'Apr',
    '5': 'May',
    '6': 'Jun',
    '7': 'Jul',
    '8': 'Aug',
    '9': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  };

  const dateConfig = {
    month: {
      format: value => monthMap[value.getMonth() + 1],
      caption: 'Mon',
      step: 1,
    },
    date: {
      format: 'DD',
      caption: 'Day',
      step: 1,
    },
    year: {
      format: 'YYYY',
      caption: 'Year',
      step: 1,
    },
  };

  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const value =
          field.value && new Date(field.value) !== 'Invalid Date'
            ? moment(field.value, FORMAT).toDate()
            : moment().toDate();

        const formattedValue = moment(value).format(FORMAT);
        return (
          <div>
            <div className="app-input-text custom-datePicker">
              {floating ? (
                <FloatingLabel
                  name={field.name}
                  placeholder={placeholderText}
                  type="text"
                  value={formattedValue}
                  readOnly
                  disabled={disabled}
                  onClick={() => handleClick()}
                />
              ) : (
                <input
                  name={field.name}
                  placeholder={placeholderText}
                  type="text"
                  readOnly
                  value={formattedValue}
                  disabled={disabled}
                  onClick={() => handleClick()}
                />
              )}
              <MobileDatePicker
                value={value}
                max={new Date()}
                min={new Date(1000, 0, 1)}
                showCaption={true}
                isOpen={visible}
                showHeader
                dateConfig={dateConfig}
                theme="dark"
                confirmText="Confirm"
                cancelText="Cancel"
                onSelect={e => handleSelect(e, form.setFieldValue, name)}
                onCancel={() => handleCancel()}
              />
            </div>
            <div className="widthSet date-picker">
              <NumberFormat
                format="##/##/####"
                placeholder={placeholderText}
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                customInput={floating ? FloatingLabel : null}
                name={field.name}
                className={`${meta.touched && meta.error ? 'error' : ''}`}
                {...field}
              />
              {meta.touched && meta.error && (
                <p className="error-message">{meta.error}</p>
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default CustomDatePicker;
