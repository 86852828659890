import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import ConditionsOfSale from './ConditionsOfSale';
import TextInput from 'components/form-elements/TextInput';

export default () => {
  const userSignatureEndRef = useRef();
  useEffect(() => userSignatureEndRef.current.scrollIntoView(), []);

  return (
    <div className="user-input-form">
      <h1 className="heading">Conditions of Sale</h1>
      <div className="text-subdued">
        <p>Agree & Sign to Continue</p>
        <ConditionsOfSale />
        <p>
          By signing below I hereby authorize Gooding & Company to charge
          registration fees to my credit card, and authorize the release of my
          financial information to Gooding & Company for the purpose of
          verifying available credit for purchases at the auction. I acknowledge
          that I have read, understand and agree to be bound by the Conditions
          of Sale as published in the "Terms" section of this online
          registration system (as may be amended and supplemented as provided in
          the Conditions of Sale), which set forth the terms and conditions
          under which Gooding & Company will offer and sell the vehicles in this
          auction and governs the rights and obligations of the parties.
        </p>
        <div className="user-signature" ref={userSignatureEndRef}>
          <p>Today's date: {moment().format('MM/DD/YY')}</p>
          <div className="initials-wrapper">
            <p>Accepted by (Initials)&#42; </p>
            <TextInput
              name="initials"
              placeholder="Your initials&#42;"
              maxLength={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
