import React, { useState, useEffect } from 'react';
import csc from 'country-state-city';
import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import DatePicker from 'components/form-elements/DatePicker';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import styles from './profileForm.module.scss';

const titleData = [
  { value: 'Mr.', label: 'Mr.', id: 'Mr.' },
  { value: 'Mrs.', label: 'Mrs.', id: 'Mrs.' },
  { value: 'Miss', label: 'Miss', id: 'Miss' },
  { value: 'Dr.', label: 'Dr.', id: 'Dr.' },
];

const Form = ({ country, setFieldValue, disabled }) => {
  const [countries] = useState(
    csc.getAllCountries().map(country => ({
      value: country.name,
      label: country.name,
      id: country.id,
    }))
  );

  const [states, setStates] = useState([]);

  useEffect(() => {
    const currentCountry = countries.find(c => c.label === country)?.id || '';
    setStates(
      csc
        .getStatesOfCountry(currentCountry)
        .map(state => ({ value: state.name, label: state.name, id: state.id }))
    );
  }, [country, countries]);

  return (
    <div className={styles.profileForm}>
      <h1 className={styles.heading}>Profile</h1>
      <p className={styles.text}>
        Completing & saving your online profile will help auto-populate our
        multiple forms within the site. This may save you time and help your
        experience.
      </p>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Personal Information</h2>
        <div className="row">
          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <Select name="title" data={titleData} placeholder="Title" />
              </div>
            </div>
          </div>

          <div className={`col-sm-12`}>
            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="firstName" placeholder="First Name&#42;" />
              </div>
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput name="lastName" placeholder="Last Name&#42;" />
              </div>
            </div>

            <div className="row">
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput
                  name="email"
                  placeholder="Email&#42;"
                  disabled={true}
                />
              </div>
              <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
                <TextInput
                  name="secondaryEmail"
                  placeholder="Secondary Email"
                />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.datePicker} col-sm-12 col-md-6`}>
                <DatePicker
                  name="birthdate"
                  placeholderText="Date of Birth&#42;"
                />
              </div>
            </div>
            <div className="row">
              <div className={`${styles.formSelect} col-md-12`}>
                <div className={styles.termsWrapper}>
                  <CheckboxInput name="emailOptOut" handleSelect={() => {}} />
                  <label htmlFor="emailOptOut">
                    I would like to opt-out of promotional emails
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Contact Information</h2>
        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber
              name="mainPhone"
              placeholder="Phone&#42;"
              label="&#42;"
            />
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <PhoneNumber name="mobilePhone" placeholder="Phone" />
          </div>
        </div>
      </div>
      <div className={styles.formSection}>
        <h2 className={styles.subHeading}>Address</h2>
        <div className={`row`}>
          <div className={`${styles.formSelect} col-md-12`}>
            <TextInput name="address1" placeholder="Address 1&#42;" />
          </div>
        </div>

        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <TextInput name="address2" placeholder="Address 2" />
          </div>
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <Select
              name="country"
              data={countries}
              placeholder="Country&#42;"
              onChangeHandler={() => setFieldValue('state', '')}
            />
          </div>
        </div>

        <div className="row">
          <div className={`${styles.formSelect} col-sm-12 col-md-6`}>
            <TextInput name="city" placeholder="City&#42;" />
          </div>
          <div className={`${styles.webViewZipCode} col-sm-12 col-md-6`}>
            <div className="row">
              <div className={`${styles.formSelect} col-6`}>
                <Select name="state" data={states} placeholder="State&#42;" />
              </div>
              <div className={`${styles.formSelect} col-6`}>
                <TextInput name="zipcode" placeholder="Zipcode&#42;" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        disabled={disabled}
        type={`${styles.saveChange} app-primary-button`}
        btnType="submit"
      >
        Save Changes
      </Button>
    </div>
  );
};

export default Form;
