const ITEMS = [
  {
    id: 1,
    title: '1 Item in your cart',
    subTitle: 'Pebble Beach Auction',
    cost: '$230.00',
    date: 'July 2, 2020',
  },
  {
    id: 2,
    title: '2 Item in your cart',
    subTitle: 'Pebble Beach Auction',
    cost: '$230.00',
    date: 'July 2, 2020',
  },
];

export { ITEMS };
