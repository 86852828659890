import * as Yup from 'yup';
import { isValidDateOfBirth } from './dateOfBirthValidation';

const INITIAL_VALUES = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  secondaryEmail: '',
  confirmEmail: '',
  phone: '',
  phone2: '',
  address1: '',
  address2: '',
  country: '',
  city: '',
  state: '',
  zipcode: '',
  acceptFinancialTerms: '',
  dateOfBirth: '',
};

const SCHEMA = Yup.object().shape({
  firstName: Yup.string().required('First Name is required.').nullable(),
  lastName: Yup.string().required('Last Name is required.').nullable(),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email is required.')
    .nullable(),
  secondaryEmail: Yup.string().email('Please enter a valid email.').nullable(),
  mainPhone: Yup.string().required('Phone is required').nullable(),
  address1: Yup.string().required('Address 1 is required.').nullable(),
  city: Yup.string().required('City is required.').nullable(),
  state: Yup.string().required('State is required.').nullable(),
  country: Yup.string().required('Country is required.').nullable(),
  zipcode: Yup.string().required('Zipcode is required.'),
  birthdate: Yup.string()
    .required('Date of Birth is required.')
    .nullable()
    .test('valid', 'Please enter valid Date Of Birth', value =>
      isValidDateOfBirth(value)
    ),
});

export { INITIAL_VALUES, SCHEMA };
