import React, { useState } from 'react';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import { SCHEMA } from 'components/utils/profileFormUtils';
import Layout from 'components/Layout';
import ProfileForm from 'features/account/profileForm';
import ProfileHeader from 'features/account/profileHeader';
import styles from './profile.module.scss';
import {
  GET_PROFILE_DATA,
  UPDATE_USER_PROFILE,
} from 'services/graphql/queries/profile';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import * as moment from 'moment';
import {
  useAuthenticatedQuery,
  useAuthenticatedMutation,
} from 'services/graphql/hooks';

const Profile = () => {
  const { loading, data, error } = useAuthenticatedQuery(GET_PROFILE_DATA);
  const [updateProfile, { error: updateError }] = useAuthenticatedMutation(
    UPDATE_USER_PROFILE
  );

  const [showSuccess, setShowSuccess] = useState(false);

  const [saving, setSaving] = useState(false);

  if (loading) return <Spinner />;

  const profile = data?.profile;

  const showSuccessMessage = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 5000);
  };

  return (
    <Layout>
      <SEO title="Profile" />
      {error && <Alert color="danger" msg={error.message} />}
      {updateError && <Alert color="danger" msg={updateError.message} />}
      {showSuccess && (
        <Alert
          color="success"
          msg="Your profile has been successfully updated"
        />
      )}
      {!error && (
        <div className={styles.profile}>
          <div className={styles.body}>
            {saving && <Spinner />}
            <ProfileHeader />
            <Formik
              validateOnMount
              initialValues={{
                ...profile,
                birthdate: profile.birthdate
                  ? moment(new Date(profile.birthdate)).format('MM/DD/YYYY')
                  : '',
              }}
              validationSchema={SCHEMA}
              onSubmit={values => {
                setSaving(true);
                const profileData = { ...values };
                if (values.birthdate)
                  profileData.birthdate = moment(values.birthdate).format(
                    'YYYY-MM-DD'
                  );
                delete profileData.__typename;
                delete profileData.email;
                return updateProfile({
                  variables: {
                    user: profileData,
                  },
                })
                  .then(showSuccessMessage)
                  .finally(() => setSaving(false));
              }}
            >
              {({ values, setFieldValue, isValid, dirty, isSubmitting }) => {
                return (
                  <Form>
                    <ProfileForm
                      country={values.country}
                      setFieldValue={setFieldValue}
                      disabled={!(isValid && dirty) || isSubmitting}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Profile;
