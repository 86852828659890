import React, { useState } from 'react';
import PropTypes from 'prop-types';
import csc from 'country-state-city';
import { navigate } from '@reach/router';
import TextInput from 'components/form-elements/TextInput';
import NumberInput from 'components/form-elements/NumberInput';
import CreditCardExpiryInput from 'components/form-elements/CreditCardExpiryInput';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import lock from 'images/lock.png';
import visa from 'images/visa.png';
import masterCard from 'images/masterCard.png';
import discover from 'images/discover.png';
import americanExpress from 'images/americanExpress.png';
import { ITEMS } from './data';
import ItemList from './itemList';
import FormList from './formList';
import './step-6.scss';
import { REGISTER_TO_BID_PATH } from 'utils/pathsConstants';

const Bidder = ({ setFieldValue, values }) => {
  const initialValue = [{ id: 0 }];
  const allowedState = [{ id: 1 }, { id: 2 }];
  const [itemList, updateList] = useState(ITEMS);
  const [formList, setFormList] = useState(initialValue);

  const countries = csc.getAllCountries().map(country => ({
    value: country.name,
    label: country.name,
    id: country.id,
  }));

  const removeItems = id => {
    updateList(itemList.filter(item => item.id !== id));
    if (itemList.length === 1) navigate(REGISTER_TO_BID_PATH);
  };

  const getStates = index => {
    const country =
      countries.find(c => c.label === values[`country${index}`])?.id || '';
    const states = csc
      .getStatesOfCountry(country)
      .map(state => ({ value: state.name, label: state.name, id: state.id }));

    return states;
  };

  const checkBoxHandler = value => {
    if (value === false) {
      setFormList(allowedState);
    } else {
      setFormList(initialValue);
    }
  };
  return (
    <div className="user-input-form step-6">
      <h1 className="heading">Complete Your In-Person Bidder Registration</h1>
      <div className="section">
        <div className="cart-section">
          <h3>YOUR CART</h3>
          <div className="pb-2 bottom-bdr">
            {itemList.length > 1 ? (
              <p className="count-item">{itemList.length} Items in your cart</p>
            ) : (
              <p className="count-item">{itemList.length} Item in your cart</p>
            )}

            {itemList.map(item => (
              <ItemList key={item.id} data={item} handleSelect={removeItems} />
            ))}
          </div>
          <div className="pt-2">
            <p className="sec">
              <span>Subtotal:</span>
              <span>$230.00</span>
            </p>
            <p className="sec">
              <span>Shipping & Handling:</span>
              <span>$49.50</span>
            </p>
            <h4 className="sec pt-3 order">
              <span>ORDER TOTAL</span>
              <span>$279.50</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="section">
        <h2 className="sub-heading">Your Email</h2>
        <div className="field-group">
          <div className="field">
            <TextInput name="email" placeholder="Email&#42;" />
          </div>
        </div>
      </div>

      <div className="section">
        <h2 className="sub-heading mt-0">Your Shipping Address</h2>
        <div className="field-group">
          <div className="cart-section">
            <span className="text-subdued">
              Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad
            </span>
          </div>
        </div>
      </div>

      {formList.map((item, index) => (
        <FormList
          key={item.id}
          index={index}
          countries={countries}
          states={getStates(index)}
          setFieldValue={setFieldValue}
        />
      ))}
      <div className="section">
        <div className="field-group">
          <div className="terms-wrapper">
            <CheckboxInput name="billAddress" handleSelect={checkBoxHandler} />
            <label className="text-subdued billAddress" htmlFor="billAddress">
              Use different billing address
            </label>
          </div>
        </div>
      </div>

      <div className="section">
        <h2 className="sub-heading">Your Payment Method</h2>
        <div className="card-section">
          <div className="field">
            <img src={lock} alt="Lock" className="lock" />
          </div>
          <div className="field">
            <img src={visa} alt="Visa" />
          </div>
          <div className="field">
            <img src={masterCard} alt="Master Card" />
          </div>
          <div className="field">
            <img src={discover} alt="Discover" />
          </div>
          <div className="field">
            <img src={americanExpress} alt="American Express" />
          </div>
        </div>

        <div className="field">
          <NumberInput
            name="cardNumber"
            format="#### #### #### ####"
            placeholder="Card Number&#42;"
          />
        </div>
        <div className="field-group mobile">
          <div className="field">
            <CreditCardExpiryInput name="exp" />
          </div>
          <div className="field">
            <NumberInput name="cvc" format="####" placeholder="CVC&#42;" />
          </div>
        </div>
      </div>
    </div>
  );
};
Bidder.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({
    formValue: PropTypes.string,
  }),
};
export default Bidder;
