import React from 'react';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';

const GridItem = ({ handleSelect, data, selected, visible }) => (
  <button
    className={`grid-item ${data.id === selected ? 'selected' : ''}`}
    onClick={() => handleSelect(data.id)}
    key={data.id}
    disabled={!visible}
  >
    {data.imgSrc && (
      <div className="image-container">
        <img src={data.imgSrc} alt={data.name} />
      </div>
    )}
    <div className="item-guts">
      <h3>{data.name}</h3>
      <p className="text-secondary">{
        new Date(data.date)
        .toLocaleDateString("en-US", {
          month: 'long',
          day: 'numeric',
          year: "numeric"
        })
      }</p>
    </div>
  </button>
);

GridItem.propTypes = {
  handleSelect: PropTypes.func,
  visible: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    imgSrc: PropTypes.string,
    name: PropTypes.string,
    date: textPropType,
  }),
};

export default GridItem;
