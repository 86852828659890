import React from 'react';
import _ from 'lodash';
import GridItem from './GridItem';

export default props => {
  const { visible, values, auctions, biddingMethod } = props;
  return (
    <div className={`select-auction ${!visible ? 'hidden' : ''}`}>
      <h3>Select Auctions</h3>
      <div className="grid">
        {
          auctions.map((item, index) => {
          if(biddingMethod) {
            if(biddingMethod === "Telephone & Absentee Bidding" || biddingMethod === "In-Person Bidding") {
              if(item.eventAttendanceMode === "OfflineEventAttendanceMode" ||
              item.eventAttendanceMode === "MixedEventAttendanceMode"){
                return (
                  <GridItem
                    {...props}
                    key={index}
                    selected={
                      _.filter(values, o => o === item.id).length ? item.id : -1
                    }
                    data={item}
                    visible={true}
                  />
                )
              }
            }
            if(biddingMethod === "Online Bidding" || biddingMethod === "Telephone & Absentee Bidding") {
              if(item.eventAttendanceMode === "OnlineEventAttendanceMode" ||
              item.eventAttendanceMode === "MixedEventAttendanceMode"){
                return (
                  <GridItem
                    {...props}
                    key={index}
                    selected={
                      _.filter(values, o => o === item.id).length ? item.id : -1
                    }
                    data={item}
                    visible={true}
                  />
                )
              }
            }
          }
          return (
            <GridItem
              {...props}
              key={index}
              selected={
                _.filter(values, o => o === item.id).length ? item.id : -1
              }
              data={item}
              visible={false}
            />
          )
        })
      }
      </div>
    </div>
  );
};
