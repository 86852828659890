import React from 'react';
import BiddingMethodItem from './BiddingMethodItem';

export default props => {
  const { auctions } = props;
  const priceRange = getAveragePricePerMethod(auctions['In-Person Bidding']);
  return (
    <div className="bidding-method">
      <h3>Select Bidding Method</h3>
      <div className="grid">
        <BiddingMethodItem
          {...props}
          title="Online Bidding"
          price={'0'}
          visible={!auctions['Online Bidding'].length > 0}
        />

        <BiddingMethodItem
          {...props}
          title="Telephone & Absentee Bidding"
          price={'0'}
          visible={!auctions['Telephone & Absentee Bidding'].length > 0}
        />

        <BiddingMethodItem
          {...props}
          title="In-Person Bidding"
          priceRange={`${priceRange.min} - ${priceRange.max}`}
          visible={!auctions['In-Person Bidding'].length > 0}
        />
      </div>
    </div>
  );

  function getAveragePricePerMethod(method) {
    const prices = [];
    method.forEach(auction => {
      if (props.values.buyerStatus) {
        auction.offers.forEach(offer => {
          offer.eligibleCustomerType.forEach(type => {
            if (type.title === props.values.buyerStatus) {
              prices.push(offer.price);
            }
          });
        });
      }
    });
    if (prices.length > 0) {
      return {
        min: Math.min(...prices)
          .toString()
          .replace('INFINITY', 0),
        max: Math.max(...prices)
          .toString()
          .replace('-INFINITY', 0),
      };
    } else {
      return {
        min: 0,
        max: 0,
      };
    }
  }
};
