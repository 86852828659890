import React, { useRef, useState } from 'react';
import FileInput from 'components/form-elements/FileInput';
import TextInput from 'components/form-elements/TextInput';
import NumberInput from 'components/form-elements/NumberInput';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import FileUpload from 'features/onlineRegistration/FileUpload';
import PDF from 'static/pdf/GCo_Bank_Letter_sample.pdf';
import './step-5.scss';
import { PRIVACY_POLICY_PATH } from 'utils/pathsConstants';

export default ({ setFieldValue, values }) => {
  const bankLetterRef = useRef(null);
  const [fileName, setFileName] = useState('');
  return (
    <div className="step-5" id="formId">
      <div className="section">
        <h1 className="heading">Financial References</h1>
        <div className="text-subdued">
        <p>If this is your first time bidding with Gooding & Company or you have not bid with us recently, we require a financial reference prior to approving your registration and issuing a bidder paddle. An original Bank Letter of Guarantee or other proof of ability to pay for purchases, in an amount and in a form acceptable to Gooding in its sole discretion, must be included to finalize your registration.
</p>
      </div>
        <h3 className="sub-heading">Bank letter of guarantee&#42;</h3>
        <div className="text-subdued">
          <p>
            An original Bank Letter of Guarantee or other proof of ability to
            pay for purchases, in an amount and in a form acceptable to Gooding
            in its sole discretion, must be included to finalize your
            registration.
          </p>
        </div>
      </div>

      <div className="section">
        <a
          className="view-letter-link"
          href={PDF}
          target="_blank"
          rel="noreferrer"
        >
          View Sample Bank Letter
        </a>
      </div>

      <div className="section">
        <div className="field-group choose-file">
          <div className="field">
            <FileUpload
              browseFile={() => bankLetterRef.current.click()}
              setValue={value => {
                setFileName(value ? value.name : null);
                setFieldValue('bankLetterFile', value);
              }}
            />
            <FileInput
              name="bankLetterFile"
              forwardedRef={bankLetterRef}
              acceptFiles="image/png,image/jpeg,application/pdf"
              errorMsg="File type must be .PNG, .JPG or .PDF"
              clickHandler={value => {
                setFileName(value ? value.name : null);
              }}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <h3 className="sub-heading">Bank information</h3>
        <div className="field-group three">
          <div className="field">
            <TextInput
              name="bankName"
              placeholder="Bank Name and Branch&#42;"
            />
          </div>
          <div className="field">
            <TextInput
              name="bankStreetAddress"
              placeholder="Bank Street Address"
            />
          </div>
          <div className="field">
            <TextInput name="bankPhone" placeholder="Bank Telephone Number" />
          </div>
        </div>
        <div className="field-group three">
          <div className="field">
            <TextInput name="bankZipcode" placeholder="Bank ZIP/Postal Code" />
          </div>
          <div className="field">
            <TextInput
              name="bankBranchOffice"
              placeholder="Branch Office / Account Officer"
            />
          </div>
          <div className="field">
            <NumberInput
              name="biddingLimits"
              placeholder="Requested Bidding Limits ($USD)&#42;"
            />
          </div>
        </div>
        <div className="field-group">
          <div className="terms-wrapper">
            <CheckboxInput
              name="acceptFinancialTerms"
              handleSelect={() => {}}
            />

            <label className="text-subdued" htmlFor="acceptFinancialTerms">
              I expressly authorize Gooding to use my financial information for
              the purposes of verifying available credit for purchases at
              auction, including by contacting my financial institution and/or
              card issuer to verify account balances and available credit. This
              information will be processed in accordance with the{' '}
              <a href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
