import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'gatsby';
import Button from 'components/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TITLE, DESCRIPTION } from 'src/data/privatePolicy';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/privacyPolicyModal';
import CheckboxInput from 'components/form-elements/CheckboxInput';
import './privacy-policy.scss';
import { useAuthenticatedMutation } from 'services/graphql/hooks';
import { UPDATE_USER_PRIVACY_POLICY } from 'services/graphql/queries/profile';
import { PRIVACY_POLICY_PATH } from 'utils/pathsConstants';

export default () => {
  const [modal, toggleModal] = useState(true);
  const [updateProfile] = useAuthenticatedMutation(UPDATE_USER_PRIVACY_POLICY);

  return (
    <div className="privacyPolicy-modal">
      <Modal
        isOpen={modal}
        toggle={() => toggleModal(!modal)}
        backdrop={'static'}
        centered={true}
        className="privacy-policy-modal"
      >
        <div className="modal-content">
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={() => {
              return updateProfile({
                variables: {
                  user: {
                    privacyPolicyAcceptanceDate: new Date(),
                  },
                },
              }).then(() => toggleModal(!modal));
            }}
          >
            {({ isValid, dirty, isSubmitting }) => {
              return (
                <Form>
                  <ModalHeader toggle={() => toggleModal(!modal)} />
                  <ModalBody>
                    <div>
                      <h3 className="modal-title">{TITLE}</h3>
                    </div>
                    <div className="modal-content">
                      <p>{DESCRIPTION}</p>
                    </div>
                    <div>
                      <div className="terms-wrapper">
                        <CheckboxInput
                          name="acceptPrivacyPolicy"
                          handleSelect={() => {}}
                        />
                        <label
                          className="text-subdued"
                          htmlFor="acceptPrivacyPolicy"
                        >
                          By selecting here, I agree to Gooding & Company’s{' '}
                          <Link
                            activeClassName="active"
                            to={PRIVACY_POLICY_PATH}
                          >
                            Privacy Policy
                          </Link>
                        </label>
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="modal-footer">
                    <Button
                      btnType="submit"
                      type="app-primary-button"
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      Accept & Continue
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};
