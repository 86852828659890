import React from 'react';
import Button from 'components/Button';
import SaveExit from 'components/SaveExit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { REGISTER_TO_BID_PATH } from 'utils/pathsConstants';

const Footer = ({
  handleBack,
  handleNext,
  isValid,
  foxyForm,
  checkout,
  method,
  step,
  isFinancialDataValid,
}) => {
  let title =
    'ARE YOU SURE YOU WANT TO LEAVE THE BIDDER REGISTRATION APPLICATION?';
  let content =
    'By leaving this page none of your bidder registration details will be saved or submitted.';
  return (
    <footer>
      <Button type="app-transparent-blue-button" handleClick={handleBack}>
        <FontAwesomeIcon icon={faLongArrowAltLeft} />
        Back
      </Button>
      <div className="flex-wrapper">
        <SaveExit
          title={title}
          content={content}
          link={REGISTER_TO_BID_PATH}
          buttonName="Save & Exit"
        />
        {step === 3 && method === 'Online Bidding' && isFinancialDataValid ? (
          <Button
            type={
              isValid ? 'app-primary-button' : ' app-primary-button inactive'
            }
            handleClick={handleNext}
          >
            Submit
          </Button>
        ) : checkout ? (
          step === 3 &&
          isFinancialDataValid &&
          method === 'In-Person Bidding' ? (
            <div>{foxyForm()}</div>
          ) : step === 4 && method === 'In-Person Bidding' ? (
            <div>{foxyForm()}</div>
          ) : step === 4 &&
            isFinancialDataValid &&
            method === 'Telephone & Absentee Bidding' ? (
            <Button
              type={
                isValid ? 'app-primary-button' : ' app-primary-button inactive'
              }
              handleClick={handleNext}
            >
              Submit
            </Button>
          ) : method === 'Online Bidding' && step === 4 ? (
            <Button
              type={
                isValid ? 'app-primary-button' : ' app-primary-button inactive'
              }
              handleClick={handleNext}
            >
              Submit
            </Button>
          ) : step === 5 ? (
            <Button
              type={
                isValid ? 'app-primary-button' : ' app-primary-button inactive'
              }
              handleClick={handleNext}
            >
              Submit
            </Button>
          ) : (
            <Button
              type={
                isValid ? 'app-primary-button' : ' app-primary-button inactive'
              }
              handleClick={handleNext}
            >
              Next
            </Button>
          )
        ) : (
          <Button
            type={
              isValid ? 'app-primary-button' : ' app-primary-button inactive'
            }
            handleClick={handleNext}
          >
            Next
          </Button>
        )}
      </div>
    </footer>
  );
};

export default Footer;
