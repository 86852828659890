import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';

const BiddingMethodItem = ({
  title,
  price,
  selected,
  handleSelect,
  visible,
  priceRange,
}) => (
  <button
    className={`grid-item ${selected === title ? 'selected' : ''}`}
    onClick={() => _.isFunction(handleSelect) && handleSelect(title)}
    disabled={visible}
  >
    <div className="item-guts">
      <h3>{title}</h3>
      <p className="text-secondary">
        <span>&#36;</span>
        {price ? price : priceRange}
      </p>
    </div>
  </button>
);

BiddingMethodItem.propTypes = {
  title: PropTypes.string,
  price: textPropType,
  selected: PropTypes.string,
  handleSelect: PropTypes.func,
  visible: PropTypes.bool,
};

export default BiddingMethodItem;
