import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SelectAuction from './SelectAuction';
import BiddingMethod from './BiddingMethod';
import './step-2.scss';

const Step2 = ({ values, setFieldValue, auctions }) => {
  const setAuction = auction => {
    if (!values.biddingMethod) return;
    _.isFunction(setFieldValue) &&
      setFieldValue('auction', _.xor(values.auction, [auction]));
  };

  const setBiddingMethod = biddingMethod => {
    values.auction = [];
    _.isFunction(setFieldValue) &&
      setFieldValue('biddingMethod', biddingMethod);
  };

  const formattedAuctions = formatAuctions(auctions);
  const filteredByBiddingMethod = filterByBiddingMethod(formattedAuctions);

  return (
    <Fragment>
      <h1 className="heading">Select Bidding Method & Auction</h1>
      <h2 className="sub-heading">Please select your desired bidding method and auction to complete your registration.</h2>      
      <BiddingMethod
        selected={values.biddingMethod}
        handleSelect={setBiddingMethod}
        auctions={filteredByBiddingMethod}
        values={values}
      />

      <SelectAuction
        visible={!!values.biddingMethod}
        values={values.auction}
        biddingMethod={values.biddingMethod}
        handleSelect={setAuction}
        auctions={formattedAuctions}
      />
    </Fragment>
  );
};

Step2.propTypes = {
  setFieldValue: PropTypes.func,
  setAuction: PropTypes.func,
  setBiddingMethod: PropTypes.func,
  values: PropTypes.shape({
    biddingMethod: PropTypes.string,
  }),
};

function formatAuctions(auctions) {
  return auctions.map(auction => {
    return {
      id: auction.objectID,
      name: auction.name,
      date: auction.date,
      imgSrc: auction.image,
      status: auction.eventStatus,
      eventAttendanceMode: auction.eventAttendanceMode,
      offers: auction.offers,
    };
  }).filter(auction => {
    return (
      auction.status !== 'EventCancelled' &&
      auction.status !== 'EventPostponed' &&
      auction.status !== 'EventAnnounced'
    );
  });
}

function filterByBiddingMethod(auctions) {
  const online = [];
  const inPerson = [];
  const tele = [];
  const filtered = auctions.filter(auction => {
    return (
      auction.status !== 'EventCancelled' &&
      auction.status !== 'EventPostponed' &&
      auction.status !== 'EventAnnounced'
    );
  });
  filtered.forEach(auction => {
    switch (auction.eventAttendanceMode) {
      case 'OfflineEventAttendanceMode':
        inPerson.push(auction);
        tele.push(auction);
        break;
      case 'OnlineEventAttendanceMode':
        online.push(auction);
        tele.push(auction);
        break;
      case 'MixedEventAttendanceMode':
        online.push(auction);
        inPerson.push(auction);
        tele.push(auction);
        break;
      default:
        return;
    }
  });
  return {
    'Online Bidding': online,
    'In-Person Bidding': inPerson,
    'Telephone & Absentee Bidding': tele,
  };
}

export default Step2;
