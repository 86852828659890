import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import NumberInput from 'components/form-elements/NumberInput';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import Select from 'components/form-elements/Select';

const FormList = ({ index, countries, states, setFieldValue }) => {
  return (
    <div>
      <div className="section">
        <div className="field">
          <TextInput name={`firstName${index}`} placeholder="First Name&#42;" />
        </div>
        <div className="field">
          <TextInput name={`lastName${index}`} placeholder="Last Name&#42;" />
        </div>
        <div className="field">
          <TextInput name={`company${index}`} placeholder="Company Name&#42;" />
        </div>
        <div className="field">
          <PhoneNumber
            name={`phone${index}`}
            placeholder="Phone Number&#42;"
            label="&#42;"
          />
        </div>
        <div className="field">
          <TextInput
            name={`bidderPrimaryAddress${index}`}
            placeholder="Address 1&#42;"
          />
        </div>
        <div className="field">
          <TextInput
            name={`bidderSecondaryAddress${index}`}
            placeholder="Address 2"
          />
        </div>
        <div className="field-group three-children">
          <div className="field">
            <TextInput name={`city${index}`} placeholder="City&#42;" />
          </div>
          <div className="field">
            <Select
              name={`country${index}`}
              data={countries}
              placeholder="Country&#42;"
              onChangeHandler={() => {
                setFieldValue(`state${index}`, '');
                setFieldValue(`city${index}`, '');
                setFieldValue(`zipcode${index}`, '');
              }}
            />
          </div>
          <div className="field-group mobile">
            <div className="field">
              <Select
                name={`state${index}`}
                data={states}
                placeholder="State&#42;"
              />
            </div>
            <div className="field">
              <NumberInput
                name={`zipcode${index}`}
                placeholder="Zipcode&#42;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormList;
