import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FileInput from 'components/form-elements/FileInput';
import Select from 'components/form-elements/Select';
import FileUpload from 'features/onlineRegistration/FileUpload';
import { OPTIONS, P1, P2 } from './data';
import './step-4.scss';

const Step4 = ({ values, setFieldValue }) => {
  const photoIdentificationRef = useRef(null);
  const additionalIdentificationRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [fileName2, setFileName2] = useState('');

  return (
    <div className="user-input-form step-4">
      <h1 className="heading">Personal Documentation</h1>
      <div className="text-subdued">
        <p>{P1}</p>
        <p>{P2}</p>
      </div>
      <div className="section">
        <h3 className="sub-heading">Photo Identification</h3>
        <div className="field-group">
          <div className="field">
            <Select
              name="photoIdentificationDocType"
              data={OPTIONS}
              placeholder="Document Type&#42;"
            />
          </div>
          <div className="field">
            <FileUpload
              browseFile={() => photoIdentificationRef.current.click()}
              setValue={value => {
                setFileName(value ? value.name : null);
                setFieldValue('photoIdentificationFile', value);
              }}
            />
            <FileInput
              name="photoIdentificationFile"
              forwardedRef={photoIdentificationRef}
              acceptFiles="image/png,image/jpeg,application/pdf"
              errorMsg="File type must be .PNG, .JPG or .PDF"
              clickHandler={value => {
                setFileName(value ? value.name : null);
                if (value === null) {
                  setFileName2(null);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={`section ${!fileName ? 'hidden' : ''}`}>
        <h3 className="sub-heading">Additional Identification</h3>
        <div className="field-group">
          <div className="field">
            <Select
              name="additionalIdentificationDocType"
              data={OPTIONS.filter(
                data => data.value !== values.photoIdentificationDocType
              )}
              placeholder="Document Type"
            />
          </div>
          <div className="field">
            <FileUpload
              browseFile={() => additionalIdentificationRef.current.click()}
              setValue={value => {
                setFileName2(value ? value.name : null);
                setFieldValue('additionalIdentificationFile', value);
              }}
            />
            <FileInput
              name="additionalIdentificationFile"
              forwardedRef={additionalIdentificationRef}
              acceptFiles="image/png,image/jpeg,application/pdf"
              errorMsg="File type must be .PNG, .JPG or .PDF"
              clickHandler={value => setFileName2(value ? value.name : null)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Step4.propTypes = {
  values: PropTypes.object,
};

export default Step4;
