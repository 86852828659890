import React from 'react';
import PropTypes from 'prop-types';
import Bidder from './Bidder';
import Telephone from './Telephone';

const Step6 = ({ setFieldValue, values, auctions }) => (
  <div className="user-input-form step-6">
    {values.biddingMethod === 'Telephone & Absentee Bidding' ? (
      <Telephone values={values} setFieldValue={setFieldValue} auctions={auctions}/>
    ) : (
      <Bidder setFieldValue={setFieldValue} values={values}></Bidder>
    )}
  </div>
);

Step6.propTypes = {
  setFieldValue: PropTypes.func,
  country: PropTypes.string,
  values: PropTypes.object,
};

export default Step6;
