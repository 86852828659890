import React from 'react';
import { Field } from 'formik';

export default ({ name, handleSelect }) => (
  <Field name={name}>
    {({ field, form }) => (
      <label className="checkbox-container">
        <input
          id={name}
          name={name}
          type="checkbox"
          checked={form.values[name]}
          onClick={() => handleSelect(form.values[name])}
          {...field}
        />
        <span className="checkMark"></span>
      </label>
    )}
  </Field>
);
