import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import ProfileIcon from 'svg/ProfileIcon';
import BookmarkIcon from 'svg/BookmarkIcon';
import styles from './header.module.scss';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const isProfile = pathname === '/auth/profile';
  return (
    <div className={styles.accountHeader}>
      <Link to="/auth/profile" activeClassName={styles.active}>
        <ProfileIcon
          color={isProfile ? '#1C5BAE' : '#000'}
          solid={!isProfile}
        />
        Profile
      </Link>
      <Link to="/auth/following" activeClassName={styles.active}>
        <BookmarkIcon color={!isProfile ? '#1C5BAE' : '#000'} />
        Following
      </Link>
    </div>
  );
};

export default Header;
