import React, { useState } from 'react';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import {
  getRegisterToBidInitialValues,
  getRegisterToBidSchema,
} from 'components/utils/formUtils';
import Layout from 'components/Layout';
import Step1 from 'privatePages/OnlineRegistration/Step1';
import Step2 from 'privatePages/OnlineRegistration/Step2';
import Step3 from 'privatePages/OnlineRegistration/Step3';
import Step4 from 'privatePages/OnlineRegistration/Step4';
import Step5 from 'privatePages/OnlineRegistration/Step5';
import Step6 from 'privatePages/OnlineRegistration/Step6';
import _ from 'lodash';
import Chevron from 'components/Chevron/Chevron';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'privatePages/OnlineRegistration/Footer';
import {
  GET_REGISTER_TO_BID_DATA,
  SEND_EMAIL,
  VERIFY_USER,
} from 'services/graphql/queries/register-to-bid';
import { UPDATE_USER_DOCS_SUBMISSION_DATE } from 'services/graphql/queries/profile';
import Spinner from 'components/Spinner';
import './online-registration.scss';
import constructMail from 'privatePages/OnlineRegistration/MailTemplate';
import Alert from 'components/Alert';
import { handleVerifyUser } from './register-to-bid.utils';
import { REGISTER_TO_BID_PATH } from 'utils/pathsConstants';
import PrivatePolicyModal from 'components/UpdatedPrivacyPolicyModal';
import useStaticQueryGetPrivacyPolicyDate from 'services/graphql/queries/register-to-bid/privacyPolicyDate';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { useAuthenticatedMutation } from 'services/graphql/hooks';

const validationSchemaArray = getRegisterToBidSchema();

export default () => {
  const { loading, data } = useAuthenticatedQuery(GET_REGISTER_TO_BID_DATA);
  const [updateProfile, { error: updateError }] = useAuthenticatedMutation(
    UPDATE_USER_DOCS_SUBMISSION_DATE
  );
  const [waitToSendEmail, setWaitToSendEmail] = useState(true);
  const [
    sendEmail,
    { error: emailError, data: emailData },
  ] = useAuthenticatedMutation(SEND_EMAIL);
  const { privacyPolicy } = useStaticQueryGetPrivacyPolicyDate();
  const [verifyUser] = useAuthenticatedMutation(VERIFY_USER);

  const [requestLoading, setRequestLoading] = useState(false);
  const sendEmailError = emailError
    ? emailError.message
    : emailData && emailData.SendEmail && emailData.SendEmail.errorMessages;
  const [step, setStep] = useState(0);
  const [formType, setFormType] = useState('');
  if (loading) return <Spinner />;
  const user = data.profile;
  const auctions = data.getAuctions;
  const showUpdatedPrivacyPolicy =
    !user.privacyPolicyAcceptanceDate ||
    new Date(user.privacyPolicyAcceptanceDate) <
      new Date(privacyPolicy.updatedAt);

  const initialValues = getRegisterToBidInitialValues(user);

  const financialSubmissionDate = user.financialDocumentSubmissionDate;
  const submitForm = React.createRef();

  const handleNext = async props => {
    const { submitForm, isValid, validateForm, setTouched } = props;

    submitForm().then(async () => {
      if (isValid) {
        if (step === 0) {
          setRequestLoading(true);
          const status = await handleVerifyUser(
            props,
            verifyUser,
            setRequestLoading
          );
          setRequestLoading(false);
          if (status !== 'Passed') {
            await navigate(REGISTER_TO_BID_PATH, {
              state: { failedVerification: true },
            });
          }
        }

        if (step === 1) {
          if (props.values.biddingMethod === 'In-Person Bidding') {
            computePrice(props, auctions);
          }
        }

        if (
          step === 3 &&
          props.values.biddingMethod !== 'Telephone & Absentee Bidding' &&
          !isFinancialDateOutdated(financialSubmissionDate)
        ) {
          setRequestLoading(true);
          await sendMail(props.values, auctions);
          await updateFinancialDate();
          setRequestLoading(false);
          await navigate(REGISTER_TO_BID_PATH, {
            state: { 
              RTBEmailSent: true,
              isApproved: !isFinancialDateOutdated(financialSubmissionDate)
            },
          });
        }

        if (
          step === 4 &&
          props.values.biddingMethod !== 'Telephone & Absentee Bidding'
        ) {
          setRequestLoading(true);
          await sendMail(props.values, auctions);
          await updateFinancialDate();
          setRequestLoading(false);
          await navigate(REGISTER_TO_BID_PATH, {
            state: { 
              RTBEmailSent: true,
              isApproved: !isFinancialDateOutdated(financialSubmissionDate) 
            },
          });
        }

        if (
          step === 4 &&
          props.values.biddingMethod === 'Telephone & Absentee Bidding' &&
          !isFinancialDateOutdated(financialSubmissionDate)
        ) {
          setRequestLoading(true);
          await sendMail(props.values, auctions);
          await updateFinancialDate();
          setRequestLoading(false);
          await navigate(REGISTER_TO_BID_PATH, {
            state: { 
              RTBEmailSent: true,
              isApproved: !isFinancialDateOutdated(financialSubmissionDate) 
            },
          });
        }

        if (
          step === 5 &&
          props.values.biddingMethod === 'Telephone & Absentee Bidding'
        ) {
          setRequestLoading(true);
          await sendMail(props.values, auctions);
          await updateFinancialDate();
          setRequestLoading(false);
          await navigate(REGISTER_TO_BID_PATH, {
            state: { 
              RTBEmailSent: true,
              isApproved: !isFinancialDateOutdated(financialSubmissionDate) 
            },
          });
        }

        if (step < 5) {
          if (props.values.biddingMethod === 'Telephone & Absentee Bidding') {
            setFormType('Telephone');
            incrementStep(1);
          } else {
            incrementStep(1);
          }
        }
        window.scrollTo(0, 0);
        validateForm();
        setTouched({});
      }
    });
  };

  const handleBack = ({ setErrors }) => {
    if (step === 0) {
      navigate(REGISTER_TO_BID_PATH);
      return;
    }

    setStep(step - 1);
    setErrors({});
  };

  const computePrice = (props, auctions) => {
    const selectedAuctions = [];
    if (props.values.auction.length > 0) {
      props.values.auction.forEach(auction => {
        selectedAuctions.push(auctions.find(x => x.objectID === auction));
      });
    }
    selectUserTypeOffer(props, selectedAuctions);
    applyDiscountAndSetPrice(props);
  };

  const selectUserTypeOffer = (props, selectedAuctions) => {
    const availableOffers = [];
    if (props.values.buyerStatus) {
      selectedAuctions.forEach(auction => {
        auction.offers.forEach(offer => {
          offer.eligibleCustomerType.forEach(type => {
            if (type.title === props.values.buyerStatus) {
              availableOffers.push({
                auctionID: auction.objectID,
                auctionName: auction.name,
                id: offer.id,
                price: offer.price,
                eligibleQuantity: offer.eligibleQuantity,
                customerType: type.title,
              });
            }
          });
        });
      });
    }
    props.values.offers = availableOffers;
  };

  const applyDiscountAndSetPrice = props => {
    if (props.values.offers) {
      const offers = props.values.offers;
      const groupedByOfferId = _.groupBy(offers, 'id');
      applyDiscount(props, groupedByOfferId);
    }
  };

  const applyDiscount = (props, offers) => {
    let prices = {};
    for (var key of Object.keys(offers)) {
      if (offers[key].length > 1) {
        if (offers[key].length.toString() === offers[key][0].eligibleQuantity) {
          // eslint-disable-next-line
          offers[key].forEach(auctionOffer => {
            if (prices[auctionOffer.auctionID]) {
              if (
                prices[auctionOffer.auctionID].eligibleQuantity <
                auctionOffer.eligibleQuantity
              ) {
                prices = { ...prices, [auctionOffer.auctionID]: auctionOffer };
              }
            } else {
              prices = { ...prices, [auctionOffer.auctionID]: auctionOffer };
            }
          });
        }
      } else {
        if (offers[key].length.toString() >= offers[key][0].eligibleQuantity) {
          // eslint-disable-next-line
          offers[key].forEach(auctionOffer => {
            if (prices[auctionOffer.auctionID]) {
              if (
                prices[auctionOffer.auctionID].eligibleQuantity <
                auctionOffer.eligibleQuantity
              ) {
                prices = { ...prices, [auctionOffer.auctionID]: auctionOffer };
              }
            } else {
              offers[key].forEach(auctionOffer => {
                prices = { ...prices, [auctionOffer.auctionID]: auctionOffer };
              });
            }
          });
        }
      }
    }
    props.values.auctionPrices = _.groupBy(prices, 'id');
  };

  const foxyForm = (values, isValid, auctions) => {
    if (values.auctionPrices) {
      return (
        <>
          <form
            action={`https://${
              process.env.STAGE === 'dev' ? 'goodingdev' : 'goodingbr'
            }.foxycart.com/cart`}
            method="post"
            acceptCharset="utf-8"
          >
            <input
              type="hidden"
              name="billing_first_name"
              value={values.firstName}
            />
            <input
              type="hidden"
              name="billing_last_name"
              value={values.lastName}
            />
            <input
              type="hidden"
              name="billing_company"
              value={values.company}
            />
            <input type="hidden" name="customer_email" value={values.email} />
            <input
              type="hidden"
              name="billing_address1"
              value={values.address1}
            />
            <input
              type="hidden"
              name="billing_address2"
              value={values.address2}
            />
            <input type="hidden" name="billing_state" value={values.state} />
            <input
              type="hidden"
              name="billing_postal_code"
              value={values.zipcode}
            />
            <input
              type="hidden"
              name="billing_country"
              value={values.country}
            />
            <input type="hidden" name="billing_phone" value={values.phone} />
            <input
              type="hidden"
              name="h:bidder_level"
              value={values.buyerStatus}
            />

            {Object.keys(values.auctionPrices).map((key, i) => {
              let name = '';
              values.auctionPrices[key].map(entry => {
                if (name) {
                  return (name = `${entry.auctionName} & ${name}`);
                } else {
                  return (name = `${entry.auctionName}`);
                }
              });
              return (
                <div>
                  <input type="hidden" name={`${i + 1}:empty`} value={true} />
                  <input type="hidden" name={`${i + 1}:name`} value={name} />
                  <input
                    type="hidden"
                    name={`${i + 1}:price`}
                    value={values.auctionPrices[key][0].price}
                  />
                </div>
              );
            })}
            <input
              type="submit"
              value="Checkout"
              ref={submitForm}
              onClick={async e => {
                const formSubmitButton = submitForm.current;
                if (waitToSendEmail) {
                  e.preventDefault();
                  setRequestLoading(true);
                  await sendMail(values, auctions);
                  await updateFinancialDate();
                  setRequestLoading(false);
                  setWaitToSendEmail(false);
                  formSubmitButton.click();
                }
              }}
              className={`${
                isValid
                  ? 'button app-primary-button'
                  : 'button app-primary-button inactive'
              }`}
            />
          </form>
        </>
      );
    } else {
      return <></>;
    }
  };

  const sendMail = async (values, auctions) => {
    const mailData = getEmailData(values, auctions);
    const mailBody = constructMail(mailData);
    const userFiles = [
      mailData['Photo Identification'],
      mailData['Additional Identification'],
      mailData['Bank Letter of Guarantee'],
      mailData['User ID'],
    ];
    const promises = userFiles
      .map(file => {
        if (file && file !== '') {
          return getBase64(file);
        } else return undefined;
      })
      .filter(file => file !== undefined);
    const attachments = await Promise.all(promises);

    await sendEmail({
      variables: {
        subject: `Register to bid - ${
          mailData['First Name'] ? mailData['First Name'] : ''
        } ${mailData['Last Name'] ? mailData['Last Name'] : ''}`,
        body: mailBody,
        attachment: attachments,
      },
    });
  };

  const getEmailData = (values, auctions) => {
    const price = getTotalRegistrationTotal(values);
    let telephoneData = {};
    if (values.biddingMethod === 'Telephone & Absentee Bidding') {
      telephoneData = {
        primaryPhone: values.primaryPhone,
        secondaryPhone: values.secondaryPhone ? values.secondaryPhone : '',
      };
      telephoneData.lots = [];
      values.auction.forEach((auction, index) => {
        telephoneData.lots.push({
          lotNumber: values[`lotNumber${index}`] || '',
          description: values[`description${index}`] || '',
          maximumBid: values[`maximumBid${index}`] || '',
        });
        if (values[`lotNumber${index}${index}`]) {
          telephoneData.lots.push({
            lotNumber: values[`lotNumber${index}${index}`] || '',
            description: values[`description${index}${index}`] || '',
            maximumBid: values[`maximumBid${index}${index}`] || '',
          });
        }
      });
    }
    return Object.assign(
      {
        'First Name': values.firstName,
        'Last Name': values.lastName,
        Company: values.company,
        EMail: values.email,
        Address: values.aboutYouAddress1,
        City: values.city,
        State: values.state,
        'Postal Code': values.zipcode,
        Country: values.country,
        'Mobile Phone': values.phone,
        'Photo Identification': values.photoIdentificationFile,
        'Photo Identification type': values.photoIdentificationDocType,
        'Additional Identification': values.additionalIdentificationFile,
        'Additional Identification type':
          values.additionalIdentificationDocType,
        'Bank Name & Branch': values.bankName,
        'Bank Street Address': values.bankStreetAddress,
        'Branch Telephone Number': values.bankPhone,
        'Bank Postal Code': values.bankZipcode,
        'Branch Officer / Account Officer': values.bankBranchOffice,
        'Requested Bidding Limit': values.biddingLimits,
        'Bank Letter of Guarantee': values.bankLetterFile,
        Initials: values.initials,
        'Date Signed': new Date().toDateString(),
        'Browser Details': navigator.userAgent,
        'User ID': values.photoIdentificationFile,
        'Registration Total': price,
        Status: values.buyerStatus,
        biddingMethod: values.biddingMethod,
        Auctions: values.auction,
        'Auctions Names': getAuctionsNames(values, auctions),
      },
      telephoneData
    );
  };

  const getTotalRegistrationTotal = values => {
    if (values.biddingMethod === 'In-Person Bidding') {
      let cost = 0;
      Object.keys(values.auctionPrices).forEach(key => {
        cost += values.auctionPrices[key][0].price;
      });
      return cost;
    }
    return 0;
  };

  const getAuctionsNames = (values, auctions) => {
    const selectedAuctions = [];
    if (values.auction.length > 0) {
      values.auction.forEach(auction => {
        selectedAuctions.push(auctions.find(x => x.objectID === auction));
      });
    }
    return selectedAuctions;
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  function isFinancialDateOutdated(date) {
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

    if (date) {
      if (twoMonthsAgo >= new Date(date)) {
        return true;
      }
      return false;
    }
    return true;
  }

  function incrementStep(increment) {
    setStep(step + increment);
  }

  async function updateFinancialDate() {
    if (isFinancialDateOutdated(financialSubmissionDate)) {
      return updateProfile({
        variables: {
          user: { financialDocumentSubmissionDate: new Date() },
        },
      });
    }
  }

  return (
    <Layout hideFooter={true}>
      <SEO title="Online Registration" />
      {showUpdatedPrivacyPolicy && <PrivatePolicyModal />}
      {sendEmailError && (
        <Alert color="danger" position="top" msg={sendEmailError.toString()} />
      )}
      {updateError && (
        <Alert color="danger" position="top" msg={updateError.toString()} />
      )}
      <Chevron amount={step + 1} />
      <div className="online-registration">
        <div className="body">
          {requestLoading && <Spinner />}
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchemaArray[
              step === 4 && !isFinancialDateOutdated(financialSubmissionDate)
                ? step + 2
                : step + 1
            ](formType)}
            onSubmit={values => {}}
          >
            {props => {
              const { values, setFieldValue } = props;
              return (
                <>
                  <Form>
                    {isFinancialDateOutdated(financialSubmissionDate) ? (
                      <>
                        {step === 0 && (
                          <Step1
                            country={values.country}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        {step === 1 && <Step2 {...props} auctions={auctions} />}
                        {step === 2 && <Step3 />}
                        {step === 3 && <Step4 {...props} />}
                        {step === 4 && (
                          <Step5 setFieldValue={setFieldValue} {...props} />
                        )}
                        {step === 5 && (
                          <Step6
                            country={values.country}
                            setFieldValue={setFieldValue}
                            auctions={auctions}
                            {...props}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {step === 0 && (
                          <Step1
                            country={values.country}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        {step === 1 && <Step2 {...props} auctions={auctions} />}
                        {step === 2 && <Step3 />}
                        {step === 3 && <Step4 {...props} />}
                        {step === 4 && (
                          <Step6
                            country={values.country}
                            setFieldValue={setFieldValue}
                            auctions={auctions}
                            {...props}
                          />
                        )}
                      </>
                    )}
                  </Form>
                  <Footer
                    step={step}
                    handleNext={() => handleNext(props)}
                    handleBack={() => handleBack(props)}
                    foxyForm={() =>
                      foxyForm(props.values, props.isValid, auctions)
                    }
                    checkout={step >= 3 && props.values.biddingMethod}
                    isFinancialDataValid={
                      !isFinancialDateOutdated(financialSubmissionDate)
                    }
                    method={props.values.biddingMethod}
                    {...props}
                  />
                </>
              );
            }}
          </Formik>
        </div>
        <Navigation step={step} maxStep={6} />
      </div>
    </Layout>
  );
};
