import React from 'react';
import NumberInput from 'components/form-elements/NumberInput';
import TextInput from 'components/form-elements/TextInput';

const AddAnother = ({ index, handler }) => {
  return (
    <div>
      <div className="field">
        <NumberInput
          name={`lotNumber${index}${index}`}
          format="##########"
          placeholder="Lot Number"
          handler={handler}
        />
      </div>
      <div className="field">
        <TextInput
          name={`description${index}${index}`}
          placeholder="Description (Year, Make, Model)"
        />
      </div>
      <div className="field">
        <NumberInput
          name={`maximumBid${index}${index}`}
          format="##########"
          placeholder="Maximum Bid"
        />
      </div>
    </div>
  );
};
export default AddAnother;
