import React, { useState } from 'react';
import NumberInput from 'components/form-elements/NumberInput';
import TextInput from 'components/form-elements/TextInput';
import AddAnother from './addAnother';

const VehicleList = ({ name, index, setFieldValue, lots }) => {
  const [flag, setFlag] = useState(false);
  const setBiddingForm = () => {
    setFlag(true);
  };
  const getLotInfo = (lotNumber, name) => {
    getDescriptionForLotNumber(lotNumber, name.substring(9, name.length))
  }
  const getDescriptionForLotNumber = (lotNumber, index) => {
    const flitteredLots = lots.filter(lot => parseInt(lot.lotNumber) === parseInt(lotNumber))
    if(flitteredLots.length > 0) {
      const lot = flitteredLots[0];
      if(lot.make) {
        setFieldValue(`description${index}`, `${lot.modelYear} ${lot.make} ${lot.model}`)
      } else {
        setFieldValue(`description${index}`, `${lot.title}`)
      }
    } else {
      setFieldValue(`description${index}`, '')
    }
  } 
  return (
    <div key={index}>
      <h2 className="sub-heading my-0">{name}</h2>
      <br/>
      <div className="field">
        <NumberInput
          name={`lotNumber${index}`}
          format="##########"
          placeholder="Lot Number"
          handler={getLotInfo}
        />
      </div>
      <div className="field">
        <TextInput
          name={`description${index}`}
          placeholder="Description (Year, Make, Model)&#42;"
        />
      </div>
      <p className="text-subdued">Note: Does not include buyer’s premium.</p>
      <div className="field">
        <NumberInput
          name={`maximumBid${index}`}
          format="##########"
          placeholder="Maximum Bid"
        />
      </div>
      {flag === true && <AddAnother index={index} handler={getLotInfo} />}

      {flag === false && <h4
        onClick={setBiddingForm}
        className="addAnotherVehicle"
        role="presentation"
      >
        + Add Another Vehicle
      </h4>}
    </div>
  );
};
export default VehicleList;
