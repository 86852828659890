import React from 'react';
import { Link } from 'gatsby';
import HeadLineWithParagraphs from './HeadlineWithParagraphs';
import './step-3.scss';

export default () => (
  <div className="conditions-of-sale text-subdued">
    <HeadLineWithParagraphs>
      <p>
        Please read the following conditions of sale ("
        <strong>Conditions of Sale</strong>") carefully prior to registering to
        bid in any Gooding &amp; Company, Inc. ("<strong>Gooding</strong>")
        auction event ("<strong>Auction</strong>"). As used herein, "General
        Conditions" refers to, collectively, the terms and conditions of (i)
        these Conditions of Sale, (ii) the Bidder Registration Application,
        (iii) the Telephone and Absentee Bidder Registration Application, and
        (iv) any notices posted by Gooding on the "Important Notices" or "Event
        Information" pages of Gooding’s website (at{' '}
        <Link to="/">
          <strong>www.goodingco.com</strong>
        </Link>
        ) (the "<strong>Site</strong>"), at the location of the Auction, or in
        any published catalogue or Lot description. The General Conditions
        constitute the sole and entire agreement between, as applicable, Gooding
        and/or the Seller (as defined below), on the one hand, and any bidder or
        the Buyer (as defined below), on the other hand, under which Gooding
        will offer and sell items, including vehicles ("
        <strong>Vehicles</strong>") and automobilia (each item, a "
        <strong>Lot</strong>"), in any Auction(s) for which a registration
        application has been accepted and concerning the respective parties’
        rights and obligations with regard to the matters addressed herein. The
        General Conditions may only be amended by written saleroom addenda or
        announcements during an Auction(s) by a Gooding representative or the
        individual conducting the Auction ("
        <strong>Auctioneer</strong>"), if any, or in a written agreement between
        the respective parties. Gooding uses a third party service provider ("
        <strong>Service Provider</strong>") to provide online bidding
        functionality on the Site and the Gooding mobile application (the "
        <strong>App</strong>"). In addition to the General Conditions, bidders
        may be required to accept the Service Provider’s terms and conditions as
        a condition to participating in an Auction via the Site or App.
      </p>
      <p>
        By registering to bid or placing a bid at an Auction – whether in
        person, through an agent or representative, by absentee bid, by
        telephone bid, by online bid via the Site or App, or by otherwise
        attending or participating in the Auction – each bidder, Buyer and
        person so attending or participating represents, warrants and covenants
        to and with Gooding that such bidder, Buyer or person attending or
        participating, as the case may be, has reviewed, understands, and
        accepts and agrees to be, and is, bound by the General Conditions,
        including the paragraphs labeled "Limitation on Damages" and "As Is – No
        Warranty," which set forth Gooding’s obligation and maximum liability in
        the event of loss or damage to any bidder, Buyer or attendee.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="REGISTERING TO BID">
      <p>
        Registration Required. No person may bid at an Auction unless
        registered. Prospective bidders may submit a request to register for an
        Auction by following the instructions displayed on the registration page
        of the Site (at{' '}
        <Link to="/register">
          <strong>www.goodingco.com/register</strong>
        </Link>
        ).
      </p>
      <p>
        <em>Financial Guarantee.</em> In each instance, prospective bidders will
        be required to complete and execute the applicable registration
        application forms and provide an original bank letter of guarantee or
        other form of proof of ability to pay for purchases, in either case, in
        an amount and in a form acceptable to Gooding in its sole discretion
        (the “<strong>Guarantee</strong>”). Prospective bidders authorize the
        release of financial information to Gooding for the purpose of verifying
        ability to pay for purchases. The Bidder Registration Application,
        Telephone and Absentee Bidder Registration Application and a sample bank
        letter of guarantee are provided at{' '}
        <Link to="/">
          <strong>www.goodingco.com</strong>
        </Link>
        .
      </p>
      <p>
        <em>Registration Timing Requirements</em>. With respect to “Geared
        Online”-branded Auctions, which are conducted by an online system that
        performs the functions traditionally performed by an Auctioneer (e.g.,
        assessment and acceptance of bids, advancement of bidding increments)
        (such system, the “<strong>Online Bidding System</strong>”, and each
        such Auction, an “<strong>Online-Only Auction</strong>”), requests to
        register as a bidder must be received at least 24 hours prior to 12:00
        am Pacific Time on the last day of the Auction (i.e., the day all Lots
        are scheduled to close). With respect to Auctions that are conducted by
        an Auctioneer (each, a “<strong>Live Auction</strong>”), requests to
        register as an absentee and telephone bidder must be received at least
        24 hours prior to the scheduled start of the session of the Auction in
        which the bidder wishes to bid. Gooding will issue each registered
        bidder a paddle, paddle number and/or other identifier for purposes of
        identification during the Auction (“<strong>Paddle</strong>”).
      </p>
      <p>
        <em>Gooding Discretion</em>. Gooding is an equal opportunity company
        that does not discriminate on the basis of race, color, national origin,
        gender, religion, age or disability in the provision of services.
        Subject to the foregoing, Gooding reserves the right, in its sole
        discretion, to refuse to register and/or issue a Paddle to any person
        with or without cause.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="BIDDER RESPONSIBILITIES">
      <p>
        <em>Principal/Agent</em>. Unless Gooding is notified in writing by the
        principal in advance of the Auction on a form and in a manner acceptable
        to Gooding in its sole discretion that the bidder is acting as an agent
        for the principal, and Gooding in its sole discretion acknowledges in
        writing such agency and issues a Paddle to such agent as an agent for
        the designated principal, each bidder shall be deemed to be bidding as a
        principal for its own account and shall be personally responsible for
        payment in full of the Purchase Price (as defined below) and other
        amounts due as Buyer and for fulfillment of all Buyer’s obligations and
        covenants under the General Conditions.
      </p>
      <p>
        Each bidder is personally responsible for any use of its Paddle, and
        each bidder agrees that, except as otherwise provided above with respect
        to agents, it is personally liable under the General Conditions for the
        full Purchase Price (as defined below) and other amounts due if the
        Paddle is used in the winning of any Lot(s), whether by the respective
        bidder or by any other person.{' '}
      </p>
      <p>
        <em>Compliance with Law</em>. By participating in the Auction and/or
        bidding, each bidder and Buyer is expressly representing and warranting
        to Gooding that it is complying with and agrees to comply with all
        applicable laws, rules and regulations, and the General Conditions.
      </p>
      <p>
        <em>Compliance with Policies</em>. Each bidder, Buyer, and other person
        attending or participating in an Auction further agrees to comply with
        all applicable policies and procedures Gooding may communicate from time
        to time, including any posted signage.{' '}
      </p>
      <p>
        <em>No Photography</em>. Without limiting the generality of the
        foregoing, Gooding does not permit any photography, audio, video, or
        other recording or broadcast of any kind inside any bidding tent without
        an official Gooding-issued press pass and an executed photography and
        video agreement, and any photography, audio, video, or other recording
        captured outside the bidding tent is solely for personal use and may not
        be redistributed or rebroadcast in any medium, nor used for any
        commercial purpose.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="GOODING AS AGENT FOR SELLER">
      <p>
        All Lots in the Auction are offered by Gooding as agent for the seller
        of the Lot (the “<strong>Seller</strong>”) and not on behalf of Gooding.
        Gooding shall not have any liability or responsibility for any act,
        omission, default or failure by any Seller.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="DESCRIPTIONS OF PROPERTY">
      <p>
        Any written or oral descriptions of the Lots or related information,
        including any published catalogue, Lot description or other
        Auction-related materials, postings, announcements, press releases,
        advertisements, bill of sale or invoice, or statements or affirmations
        made by or on behalf of Gooding prior to or at the Auction, including
        any reference to a Lot as being a particular model, make, or type or the
        rarity or uniqueness or provenance thereof, are only statements of
        opinion and not statements of fact and do not, and shall not under any
        circumstances be deemed to, create or constitute representations or
        warranties of any kind by Gooding or the Seller. Each bidder and Buyer
        hereby acknowledges and agrees that they are not entitled to rely, and
        they each agree not to rely, upon any of such descriptions, statements,
        affirmations, information or materials for any purpose, and that none of
        the foregoing are a part of the basis of the bargain of the purchase and
        sale of any Lot, nor do or shall any of the foregoing supersede or
        modify any of the General Conditions.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="INSPECTION OF LOTS">
      <p>
        <em>Viewing</em> Hours and Appointments. During viewing hours or by
        appointment (as applicable), bidders are provided an opportunity to
        inspect the Lots prior to bidding and are invited and strongly
        encouraged to conduct, and must solely rely upon, their own independent
        inspection and examination of any Lot and all matters relating thereto
        before placing any bid. Refer to the published event information,
        including the “Event Information” page on the Site, for the viewing
        hours and information for scheduling appointments.{' '}
      </p>
      <p>
        <em>Buyer Responsibility</em>. Gooding has no obligation or
        responsibility, and does not assume any obligation or responsibility, to
        undertake an inspection of any Lot prior to the Auction. Bidders are
        solely responsible for inspecting the Lots prior to bidding (regardless
        of whether Gooding conducts its own inspection of the Lot) and
        satisfying themselves as to the condition of the Lots and all other
        matters relating to the Lots, including condition, title, importance,
        value, quality, authenticity, description (including the published Lot
        description), provenance and whether or not any Lot complies with any
        applicable federal, state or other laws or regulations of any kind, and
        to identify any repairs, restoration or replacement parts and to
        ascertain completeness. As the condition and other potentially relevant
        matters related to a Lot are not necessarily easily determined, and
        Gooding does not claim or possess any peculiar knowledge or experience
        in determining such matters and does not have access to relevant
        information, Gooding does not, and does not assume any obligation or
        responsibility to, undertake a level of inspection necessary to obtain
        or verify such information, including any published Lot description or
        other description or announcement or affirmation, and Gooding relies
        upon information provided by the Seller.
      </p>
      <p>
        <em>No Warranty on Condition</em>. An employee, officer, agent or
        representative of Gooding may provide a limited verbal or written
        condition report upon request. However, Gooding does not assume any
        responsibility to determine the condition of any Lot, and any condition
        report (and/or any other description or information provided relating to
        the Lot, including in the published Lot description) is not intended to
        and does not negate or modify the paragraph labeled “As Is – No
        Warranty” or any other provisions of the General Conditions, contains
        only statements of opinion and not statements of fact, and shall not be
        deemed to create representations or warranties of any kind. In no event
        shall Gooding or any of its directors, employees, officers, agents or
        representatives have any liability or responsibility for any matters
        relating to or arising out of such report and/or the condition of any
        Lot, regardless of the content or omissions of, or any errors in, the
        report. The absence with regard to a Lot of any mention of repairs,
        damage, replacement parts or restoration in any condition report,
        published Lot description or statements by Gooding shall not be deemed
        to mean the Lot is free of the foregoing, nor shall reference to any of
        the foregoing be deemed to exclude any others.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="CERTIFICATE OF TITLE; REGISTRATION">
      <p>
        Gooding makes no representation or warranty with respect to any existing
        certificate of title or registration with regard to any Lot. It is each
        bidder’s and Buyer’s responsibility to make their own independent
        investigation with regard to the title, including examining any and all
        documentation pertaining to the title provided by the Seller and made
        available by Gooding prior to the Auction, and determining whether a Lot
        can be registered. Gooding does not guarantee or make any representation
        or warranty that any or all title documentation or registration will
        satisfy the requirements of any state in the United States or of any
        foreign jurisdiction, and Gooding shall not be liable under any
        circumstances with regard to whether or not such documentation is
        acceptable, complete or adequate, or otherwise in connection therewith.
        All such title documentation is provided by the Seller, and Gooding does
        not assume any responsibility or liability with regard to such title
        documentation or the quality or marketability of title to any Lot.
      </p>
      <p>
        The Buyer of a Lot is solely responsible for complying with all
        registration requirements as to such Lot. Subject to the foregoing, for
        Vehicles purchased at any Live Auction held in California or Online-Only
        Auction, Gooding will manage the title transfer process on the Buyer’s
        behalf, send the Buyer the title by courier after the Auction and assess
        a documentation fee in the amount of $80.00 per Vehicle. Upon receipt of
        such title, the Buyer is responsible for registering the Vehicle at its
        final destination.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="ABSENTEE, TELEPHONE, AND ONLINE BIDDING">
      <p>
        <em>Generally</em>. Subject to availability during the Auction and prior
        approval in Gooding’s discretion, with respect to one or more Lots as
        determined in Gooding’s discretion, Gooding, only as an accommodation
        and convenience, may offer absentee, telephone and online bidding to
        bidders who cannot attend a Live Auction, as well as absentee and
        telephone bidding to bidders participating in an Online-Only Auction.
        Telephone bids and the bidder’s telephone conversation with Gooding’s
        employee, officer, agent or representative may be recorded, and by
        participating or bidding at the Auction by phone the bidder expressly
        consents to all such recording.
      </p>
      <p>
        <em>Bidding Increments</em>. Bidding will be opened at a level
        determined by Gooding (via the Online Bidding System) or the Auctioneer,
        as applicable (“<strong>Auction Conductor</strong>”), and will generally
        advance in increments of approximately 10% or as may be otherwise set
        forth in notices posted by Gooding on the “Important Notices” or “Event
        Information” pages of the Site. Absentee, telephone or online bids that
        do not conform to these increments may be lowered to the next bidding
        increment as determined by the Auction Conductor. Gooding will attempt
        to execute absentee bids and any emergency covering bids for telephone
        bidding and online bids at the lowest price, taking into account
        reserves and bids from all other parties and determining bid precedence
        in the event of simultaneous or near-simultaneous bids. Each bidder also
        authorizes Gooding to bid on the bidder’s behalf on the Lots specified
        up to the amount specified.
      </p>
      <p>
        <em>Countdown Clock</em>. With respect to any Lot in an Online-Only
        Auction, at least two minutes must elapse between placement of the final
        bid and the close of bidding. In furtherance of the foregoing, with
        respect to any such Lot, if a bid is placed with fewer than two minutes
        remaining on the countdown clock for bidding (“
        <strong>Countdown Clock</strong>”), then the bidding period will be
        extended and the Countdown Clock will be reset at two minutes, and such
        extensions will continue indefinitely until there is no more bidding
        activity on the Lot for a period of two consecutive minutes. The
        extension of any one Lot’s closing time will not affect other Lots’
        closing times; therefore, in any Online-Only Auction, Lots may close out
        of numerical Lot order.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="CURRENCY CONVERTER">
      <p>
        Gooding may provide a currency converter during the Auction as an
        accommodation to bidders who wish to follow the bidding in foreign
        currencies, and any rates quoted or displayed are only indications of
        the conversion amount and shall not be relied upon by any bidder or
        Buyer for any purpose, including bidding. Gooding shall not be liable or
        responsible for any errors or omissions in connection with such currency
        conversions or rates, and the bidder accepts and assumes all
        responsibility and liability for any errors or omissions relating
        thereto and for calculating any conversion rates. Currency conversion
        rates may change from the time of bidding to the time of payment. All
        bids are made in and must be paid in U.S. Dollars. With respect to
        Online-Only Auctions, bids will display in U.S. Dollars only.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="ESTIMATES">
      <p>
        Estimates for the Lots published in any Lot description do not include
        the Buyer’s Premium (as defined below) or any taxes, are only opinions,
        are prepared well in advance of the Auction, are subject to change, and
        may be amended either orally or in writing prior to or at the Auction.
        Estimates should not be relied upon, and each bidder and Buyer hereby
        agrees not to rely upon any estimate, as fact or a guarantee or
        prediction of the actual selling price or value of a Lot, and Gooding
        shall not have any liability with regard to any estimates under any
        circumstances.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="RESERVES">
      <p>
        Unless otherwise specified in the published Lot description or announced
        at the Auction, the Lots will be offered subject to a reserve, which is
        the minimum amount that the Seller is willing to accept with regard to
        the respective Lot. Reserves are confidential and will not exceed the
        low presale estimate. Gooding may act to protect the reserve by bidding
        through the Auction Conductor, which may open bidding below the reserve
        by placing a bid on behalf of the Seller, place bids on behalf of the
        Seller up to the reserve amount, place bids in response to other bidders
        or elect to withdraw the Lot from bidding if the opening bid is below
        the reserve. The Auction Conductor shall not be obligated to
        specifically identify bids placed on behalf of the Seller to protect the
        reserve nor will the Auction Conductor place a bid on behalf of the
        Seller at or above the reserve. Sellers may not bid on Lots they have
        consigned to Gooding without prior announcement at the Auction.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="AUCTION CONDUCTOR’S ROLE/BIDDING DISPUTES">
      <p>
        <em>Generally</em>. The Auction Conductor has the absolute right and
        ability to refuse any bid or to split or advance bidding in any
        increments, regardless of any increment parameters set forth in the
        published Lot description or otherwise. The Auction Conductor makes the
        final determination about the validity of any bid, any error relating to
        bidding or any bid (including error by the Auction Conductor or
        Gooding), or any dispute between bidders, whether bids are made in
        person, through an agent or representative, by absentee bid, by
        telephone bid, by online bidding, or by otherwise attending or
        participating in the Auction. The Auction Conductor has the absolute
        right and ability to choose to continue bidding, refuse a bid, cancel a
        bid, determine the successful bidder (including in the event of
        simultaneous or near-simultaneous bids), withdraw a Lot, reoffer a Lot,
        reopen bidding or restart the bidding, as the case may be, including in
        the event of concern, error, dispute or doubt with regard to any bid or
        bidding. In any dispute, Gooding’s records (including the records of the
        Online Bidding System, as applicable), in each case whether audio,
        visual or written, are conclusive.{' '}
      </p>
      <p>
        <em>Buyer’s Premium; Taxes</em>. Bid amounts, including the amount of
        any winning bid or final bid price, do not include the Buyer’s Premium
        or any taxes.
      </p>
      <p>
        <em>Bids Are Final</em>. Each bid is final, binding, and irrevocable,
        and bidders may not change, retract or rescind any bid once made.{' '}
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="THE BUYER">
      <p>
        <em>Binding Contract</em>. The buyer (“<strong>Buyer</strong>”) is the
        highest bidder acknowledged by the Auction Conductor and accepted by
        Gooding, subject to the reserve, if any, and the terms of the General
        Conditions. The fall of the Auctioneer’s hammer (with respect to Live
        Auctions) or the moment when the Countdown Clock reaches zero (with
        respect to Online-Only Auctions), as applicable (the “
        <strong>Fall of the Hammer</strong>”), marks the acceptance of the
        Buyer’s bid and a contract of purchase and sale is made between the
        Buyer and Seller. Gooding is not a party to the contract of purchase and
        sale and Gooding shall not be liable or responsible for any breach or
        default of such contract by either Buyer or Seller. All sales are final.
      </p>
      <p>
        <em>Risk of Loss</em>. Upon the Fall of the Hammer, the Buyer is
        thereupon responsible for and assumes all risk of loss or damage to such
        Lot. Gooding, its employees, officers, directors, agents and
        representatives shall not be liable for any loss or damage to or any
        loss or damage caused by any Lot at any time.{' '}
      </p>
      <p>
        <em>Confirmation of Purchase</em>. With respect to Live Auctions,
        immediately following the Fall of the Hammer, any Buyer that is
        physically present at the Auction shall be required to execute a
        Confirmation of Purchase, which confirms Buyer’s purchase of the Lot as
        announced by the Auctioneer.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="BUYER’S PREMIUM">
      <p>
        In addition to the final bid price, the Buyer is required to pay Gooding
        a percentage of the final bid price, which Gooding retains (the “
        <strong>Buyer’s Premium</strong>”). Gooding’s Live Auctions in the U.S.
        and Online-Only Auctions will have the following Buyer’s Premiums:
      </p>
      <ul>
        <span>For Vehicle Lots:</span>
        <li>
          Live Auctions in the U.S.: (a) For a final bid price of $250,000 and
          below, Gooding will receive a Buyer’s Premium of 12% of the final bid
          price; and (b) for a final bid price of above $250,000, Gooding will
          receive a Buyer’s Premium of 12% on the first $250,000 and 10% on the
          remainder of the final bid price.
        </li>
        <li>
          Online-Only Auctions: Gooding will receive a Buyer’s Premium of 10% of
          the final bid price.
        </li>
      </ul>
      <ul>
        <span>For Automobilia Lots:</span>
        <li>
          Gooding will receive a Buyer’s Premium of 25% of the final bid price.
        </li>
      </ul>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="PAYMENT">
      <p>
        <em>Purchase Price</em>. The purchase price to be paid by the Buyer is,
        and the Buyer acknowledges it is responsible for and agrees to timely
        pay, the sum of the final bid price (the highest bid acknowledged and
        accepted by the Auction Conductor), plus (i) the Buyer’s Premium and
        (ii) all applicable state and local sales and/or use taxes and license
        fees (such taxes and fees to be determined by Gooding in its sole
        discretion) (collectively, the “<strong>Purchase Price</strong>”).{' '}
      </p>
      <p>
        <em>Payment Terms</em>. Payment of the full Purchase Price is due to
        Gooding no later than the Wednesday following completion of the Auction
        by (x) 5:00 pm local time of the Auction location with respect to Live
        Auctions or (y) 5:00 pm Pacific Time with respect to Online-Only
        Auctions.
      </p>
      <p>
        <em>Late Payment Fees</em>. Gooding reserves the right to impose a late
        charge of up to a maximum of 18% per annum (or the maximum rate
        permitted by law if less than 18%) on any portion of the Purchase Price
        if the Buyer does not make timely payment of the Purchase Price when due
        under these Conditions of Sale.{' '}
      </p>
      <p>
        <em>Other Amounts</em>. In addition, the Buyer acknowledges it is
        responsible for, and agrees to timely pay, (a) any and all costs and
        expenses incurred on behalf of the Buyer and/or as a result of the
        Buyer’s failure to timely and fully comply with the General Conditions,
        including removal, transport and storage costs for the Lot and taxes
        thereon and (b) all other amounts due and owing pursuant to the General
        Conditions.
      </p>
      <p>
        <em>Payment Method</em>. All payments must be made by wire transfer,
        certified bank check, cashier’s check or, with prior approval of
        Gooding’s finance department, personal or company check. Unless
        otherwise agreed to by Gooding in writing, Gooding will not accept
        payment of any portion of the Purchase Price from any party other than
        the Buyer.
      </p>
      <p>
        <em>Passage of Title</em>. Title to a Lot does not pass to the Buyer
        until good and cleared funds in the amount of the full Purchase Price
        are received by Gooding.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="PROMOTIONAL PRICING">
      <p>
        Gooding may offer certain promotional pricing (including waiving certain
        fees) from time to time that differs from standard rates for
        registration and the like (“<strong>Promotional Pricing</strong>”), and
        may discontinue such Promotional Pricing at any time. Such Promotional
        Pricing is offered in Gooding’s sole discretion, and offering
        Promotional Pricing to one or more individuals on a particular occasion
        does not constitute a commitment to offer the same Promotional Pricing
        on any other occasion, or to any other individuals on any particular
        occasion.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="COLLECTION, REMOVAL, AND STORAGE">
      <p>
        Following the Auction, Buyer must remove all Lots from the Auction site,
        Gooding facility or other location as directed by Gooding (as
        applicable) at Buyer’s risk and expense, no later than (x) 5:00 pm local
        time on the Wednesday following completion of the Auction location with
        respect to Live Auctions or (y) 5:00 pm Pacific Time on the Friday
        following completion of the Auction with respect to Online-Only
        Auctions. If not removed timely by Buyer, in addition to any and all
        other remedies and rights Gooding may have, Gooding shall have the right
        (but not the obligation) to remove any Lot at Buyer’s sole risk and
        expense and store it, at Buyer’s expense, including removal, storage,
        transportation and handling expenses and costs and applicable taxes, at
        a third-party warehouse, and Buyer hereby grants Gooding an irrevocable
        power of attorney to remove and store such Lots at Buyer’s expense.
        Except in Gooding’s sole discretion, no Lot shall be released to Buyer
        until Gooding has received payment in full, in good cleared funds, of
        the Purchase Price for the Lot, and Buyer has fully complied with the
        General Conditions.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="ROADWORTHINESS/COMPLIANCE">
      <p>
        Notwithstanding any oral or written statement or description of any kind
        regarding any Vehicle, whether by or on behalf of Gooding or Seller,
        including in the published Lot description, all Vehicles are sold “as
        is” and only as collector’s items and Vehicles may not be suitable for
        use on the road. The Buyer of any Vehicle who intends to use the Vehicle
        on the road is responsible for determining roadworthiness and for
        complying with all applicable regulations regarding title, registration,
        insurance, emission control and safety equipment and the Buyer assumes
        all risk of whether any such Vehicle will or does comply, and neither
        Gooding nor Seller shall have any liability or responsibility of any
        kind for failure to comply by or lack of roadworthiness of any Vehicle.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="EXPORT PERMITS">
      <p>
        The Buyer is responsible for determining whether or not an export permit
        is required to export a Lot and for obtaining and paying the costs of
        any permits or licenses necessary to export any Lot from the United
        States and/or import it into any other country, and for the payment of
        any import duty that may be levied by the country of import. Gooding
        makes no representation or warranty of any kind with regard to whether
        or not any Lot can be exported or imported or the issuance of an export
        or import permit for any Lot.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="DATA USE">
      <p>
        More information regarding how Gooding uses personal information to
        provide services can be found in Gooding’s Privacy Policy, which is
        available for review at{' '}
        <Link to="/privacy">
          <strong>www.goodingco.com/privacy</strong>
        </Link>
        . Please contact Gooding with any questions or comments about the
        Privacy Policy, your personal information, our third-party disclosure
        practices, or your consent choices, via email to privacy@goodingco. com,
        or by mail to 1517 20th St., Santa Monica, CA 90404, United States.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="COPYRIGHT">
      <p>
        Gooding shall own the copyright in all photographs, videos,
        illustrations and written descriptions of the Lots created by or for
        Gooding. Without limiting the foregoing, Gooding shall have the right to
        use, publish, display and reproduce any and all of the foregoing for any
        purpose and in any media at its discretion at any time in any manner
        whether before and/or after the Auction, and no bidder or Buyer shall
        have any right, title or interest to any of the foregoing. Each bidder
        and Buyer agrees they will not use, reproduce or publish for any purpose
        any such photographs, videos, illustrations or written descriptions
        created by or for Gooding.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="LIKENESS">
      <p>
        Each bidder, Buyer, or other person attending or participating in an
        Auction or inspection grants all necessary rights to Gooding and agrees
        that Gooding has permission and license to use his or her likeness for
        television, video, still photography and/or any other visual or audio
        recording, display, transmission, broadcast and/or publication for any
        and all purposes (including advertising and marketing) at any time or
        times, including concurrent with and/or subsequent to the Auction or
        inspection, and waives all rights or claims (including residual rights
        and rights of privacy and publicity) that might arise from use of any
        attendee’s image, voice, picture or likeness for any purpose, including
        at any future date, under all applicable laws, including common law or
        by statute.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="GOODING’S RIGHTS">
      <p>
        Gooding reserves the right, in its sole discretion, to (a) withdraw any
        Lot at any time prior to or at the Auction, (b) postpone or cancel the
        Auction of any or all of the Lots for any reason, (c) reject or refuse
        any bid from any party before or during the Auction, (d) revoke any
        bidder’s Paddle or otherwise withdraw Gooding’s permission for the
        bidder to participate in the Auction or use the Site or App (e)
        terminate a registered bidder’s bidding account or (f) rescind or
        terminate any sale if Gooding determines in its sole discretion that it
        cannot complete the transaction for any reason, in which event Gooding
        shall refund any payment by Buyer which has not yet been transferred to
        Seller, as well as any Buyer’s Premium paid to Gooding, and Buyer shall
        have no further remedy of any kind against Gooding notwithstanding
        anything to the contrary in the General Conditions. Each bidder and/or
        Buyer agrees that Gooding shall not have any liability whatsoever to any
        bidder for exercise of any of the foregoing rights, or for any errors in
        execution or failure to execute any bid(s), regardless of circumstances
        or events of any kind.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="NONCOMPLIANCE OF BUYER/REMEDIES">
      <em>Remedies</em>. If the Buyer or a bidder fails to comply with any of
      the General Conditions, including if a Buyer or bidder fails to timely pay
      the full Purchase Price and/or remove a Lot when and as required by these
      Conditions of Sale, the Buyer or bidder, as the case may be, shall be in
      breach and default of the General Conditions, and shall be liable to
      Gooding, and each bidder and Buyer agree that Gooding shall be entitled to
      recover from such Buyer or bidder, as the case may be, any and all
      damages, costs, expenses, fees (including attorneys’ fees) and other
      amounts suffered and/or incurred by Gooding as a result of such failure to
      comply (including any such damages, costs, expenses, fees and other
      amounts suffered or incurred as a result of suit(s) by the Seller or other
      third parties). Further, in such event of a failure to comply, Gooding
      may, in its sole discretion, at any time and from time to time, in
      addition to all other available remedies under the General Conditions, at
      law and otherwise, and without any notice to such Buyer or bidder, take
      any one or more of the following actions to the fullest extent allowed by
      law: (a) hold the defaulting Buyer or bidder, as the case may be, liable
      for the Purchase Price, all other amounts due under the General Condition,
      and all damages, costs and expenses suffered or incurred by Gooding as a
      result of such default and failure to comply, including initiating legal
      action against the Buyer or bidder, including an action for specific
      performance; (b) cancel or rescind the sale; (c) resell the Lot(s) with or
      without reserve privately or at public auction, in which case the Buyer or
      bidder shall be liable for any deficiency between the Purchase Price and
      other amounts due under the General Condition (plus all damages, costs,
      expenses, fees (including attorneys’ fees) incurred by Gooding as a result
      of noncompliance and all commissions, premiums and fees applicable to such
      resales) and the price received upon the resale; (d) remove or arrange for
      the removal and storage of the Lot at the sole risk and cost of such
      defaulting Buyer or bidder; (e) offset any sums due from Gooding to the
      Buyer or bidder against the Purchase Price and all other amounts due and
      owing by the Buyer or bidder under the General Conditions; (f) repossess
      the Lot(s) for which the Purchase Price or any portion thereof is unpaid,
      and resell such Lot(s) and apply the sale proceeds against amounts owed by
      such Buyer or bidder under the General Conditions; and/or (g) take any
      other action that Gooding in its sole discretion deems necessary or
      desirable, including any combination of the foregoing remedies.
      <p>
        <em>Short Payments</em>. If Buyer or bidder has purchased more than one
        Lot and Gooding receives less in payment than the total due for all Lots
        purchased, Gooding may apply the funds received toward payment of
        whichever Lot(s) it selects in its sole discretion, regardless of what
        the Buyer or bidder may or may not direct.
      </p>
      <p>
        <em>Security Interest</em>. By registering to bid or by placing a bid at
        the Auction, each Buyer and bidder hereby agrees to grant Gooding, and
        Gooding shall be deemed to have, a first priority security interest in,
        and Gooding may retain as collateral security for such Buyer’s or
        bidder’s obligations to Gooding, any Lot and any other property in
        Gooding’s possession owned by such Buyer or bidder and any and all
        proceeds (including proceeds as defined under the Uniform Commercial
        Code in effect in California), including insurance proceeds, of any of
        the foregoing. Gooding shall have all the rights and remedies provided
        to a secured party under the UCC and shall be entitled to file one or
        more financing statements as Gooding may desire, with regard to any and
        all such Lots and other personal property, and shall be entitled to sell
        any such property in Gooding’s possession and apply against the Purchase
        Price and other amounts due and owing by the Buyer or bidder the
        proceeds from such sale. If Buyer or bidder defaults and Gooding elects
        its discretion to pay the Seller a portion of the net proceeds due
        Seller with regard to the sale of the Lot, Gooding shall have all the
        rights and remedies of Seller against the Buyer or bidder, including the
        right to pursue the Buyer or bidder for all such amounts and any and all
        other amounts, damages, costs, fees and expenses as may be available
        under the General Conditions, at law, in equity or otherwise.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="GOVERNING LAW; JURISDICTION">
      <p>
        <em>California Law</em>. The Auction, the General Conditions and the
        relationship and rights and obligations of the parties shall be governed
        solely and exclusively by the laws of the state of California, without
        regard to such state’s choice of law rules and regardless of where the
        bidder or Buyer is located or received any Auction materials.
      </p>
      <p>
        <em>Binding Arbitration</em>. Each bidder and Buyer agree that any
        dispute (including any claim, counterclaim or other action) or
        controversy arising out of or relating to any of the General Conditions,
        the Auction or any Lot shall be brought and determined by arbitration in
        Los Angeles, California before one arbitrator. The arbitration shall be
        administered by JAMS pursuant to its Comprehensive Arbitration Rules and
        Procedures or pursuant to JAMS’ Streamlined Arbitration Rules and
        Procedures. The determination of the scope or applicability of this
        Agreement to arbitrate shall be solely determined by JAMS. Judgment on
        an award may be entered in any court having jurisdiction. In the event
        that any party seeks relief under this Agreement in any venue other than
        through JAMS (including federal or state court), then the complaining
        party shall pay all of the costs, fees, including attorneys’ fees, and
        expenses of the responding party. Each party shall pay its own
        proportionate share of arbitrator fees and expenses and/or the
        prevailing party shall be reimbursed for its expenses, including
        reasonable attorneys’ fees. The parties waive their right to a jury
        trial and the right of appeal. In the event that either files any claim
        against any shareholder, employee, officer, director, member, manager,
        representative, principal, or agent of the other party, then the parties
        hereby agree that any such claims shall also be subject to this dispute
        resolution section. Each bidder and Buyer, by registering to bid at or
        participating in the Auction (whether in person, through an agent or
        representative, by absentee bid, by telephone bid, by online bid, or by
        otherwise attending or participating in the Auction), and any person
        attending the Auction, hereby submits to the jurisdiction of state and
        federal courts in California.
      </p>
      <p>
        <em>Waiver of Service of Process</em>. Each bidder and Buyer by
        registering to bid at or participating in the Auction (whether in
        person, through an agent or representative, by absentee bid, by
        telephone bid, by online bid, or by otherwise attending or participating
        in the Auction), and any person attending the Auction, hereby consents
        to waiver of service of process pursuant to California or federal rules
        of civil procedure, so long as notice is provided in English via Federal
        Express or similar courier, with copies via facsimile or e-mail.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="BINDING ON SUCCESSORS">
      <p>
        The General Conditions shall be binding on each attendee or participant,
        as the case may be, in the Auction, and each bidder and Buyer and
        Gooding, and their respective agents, heirs, executors, legal
        representatives, successors and assigns.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="ATTORNEYS’ FEES">
      <p>
        If Gooding engages counsel to assist it to enforce any part of the
        General Conditions, with regard to any bidder, attendee, Buyer or other
        party, then such bidder, attendee, Buyer or other party, as the case may
        be, shall be responsible for all reasonable fees (including attorneys’
        fees), costs and all expenses incurred by Gooding (including costs and
        all expenses related to evaluating, analyzing, assessing, enforcing,
        preparing for and conducting litigation of any kind or nature), whether
        or not legal proceedings are commenced. If proceedings (including
        arbitration proceedings) are initiated with regard or relating in any
        way to or arising out of the General Conditions, any Lot and/or the
        Auction, then the prevailing party shall recover its reasonable
        attorneys’ fees, costs and expenses; provided, however, and
        notwithstanding the foregoing, if a bidder, attendee, Buyer or other
        party fails to first present any claim directly to Gooding and allow for
        a reasonable opportunity and period to mediate (and participate in such
        mediation of) such claim before initiating legal action against Gooding
        or any of its officers, directors, employees, agents or representatives,
        then such bidder, attendee, Buyer or other party as the case may be,
        shall not be entitled to recover on any such claim any attorneys’ fees
        whether or not such bidder, attendee, Buyer or other party prevails on
        such claim.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="LIMITATION ON DAMAGES">
      <p>
        <em>Presentation of Claims; Exclusion of Certain Liabilities</em>. BY
        ATTENDING OR PARTICIPATING IN THE AUCTION WHETHER IN PERSON, THROUGH AN
        AGENT OR REPRESENTATIVE, BY TELEPHONE BID, ONLINE BID OR ABSENTEE BID,
        EACH ATTENDEE AT THE AUCTION AND EACH BIDDER AND BUYER AGREES THAT (A)
        ANY CLAIMS RELATING TO THE AUCTION, SALE OF A LOT OR THE GENERAL
        CONDITIONS MUST BE FIRST PRESENTED DIRECTLY TO GOODING BEFORE FILING A
        LEGAL ACTION AND (B) GOODING SHALL NOT HAVE ANY LIABILITY TO ANY
        ATTENDEE, BIDDER, BUYER OR OTHER PARTY FOR INJURY OR DAMAGE OF ANY KIND
        RESULTING FROM OR RELATING TO THE NEGLIGENCE OF GOODING OR ANY OF ITS
        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES, ACTIVE OR
        OTHERWISE, OR RELATING IN ANY WAY TO ANY LOT(S) OR THE AUCTION OR ANY
        ACT OR OMISSION OF ANY OF THE FOREGOING, UNLESS GOODING OR ITS
        EMPLOYEES, OFFICERS, DIRECTORS, AGENTS OR REPRESENTATIVES ARE GROSSLY
        NEGLIGENT.
      </p>
      <p>
        <em>Liability Cap</em>. GOODING’S SOLE LIABILITY AND DAMAGES, AND EACH
        ATTENDEE’S, BIDDER’S, BUYER’S AND OTHER PARTY’S SOLE AND EXCLUSIVE
        REMEDY, IF ANY, AT LAW, UNDER THE GENERAL CONDITIONS AND OTHERWISE AND
        ARISING OUT OF OR DIRECTLY OR INDIRECTLY RELATING IN ANY WAY TO THE
        AUCTION, ANY LOT(S) (INCLUDING ANY INABILITY TO DELIVER POSSESSION OF A
        LOT TO THE BUYER), THE GENERAL CONDITIONS OR OTHERWISE, ARE IN ALL
        EVENTS AND CIRCUMSTANCES LIMITED EXCLUSIVELY TO AND SHALL NOT EXCEED AN
        AMOUNT EQUAL TO THE BUYER’S PREMIUM PAID BY SUCH BUYER TO GOODING WITH
        REGARD TO SUCH RESPECTIVE LOT, IF ANY, OR ADMITTANCE OR REGISTRATION
        FEES, IF ANY, PAID TO GOODING BY THE RESPECTIVE BUYER, ATTENDEE OR
        BIDDER TO ATTEND AND/OR BID AT THE AUCTION, AS THE CASE MAY BE.
      </p>
      <p>
        <em>
          No Special, Indirect, Punitive, Consequential or Incidental Damages
        </em>
        . THE AMOUNTS PAYABLE BY ANY ATTENDEE, BIDDER OR BUYER ARE NOT
        SUFFICIENT TO WARRANT GOODING ASSUMING ANY RISK OF DAMAGES OF ANY KIND,
        INCLUDING CONSEQUENTIAL DAMAGES TO THE BIDDER, ATTENDEE OR BUYER AS A
        RESULT OF OR RELATING TO ANY NEGLIGENCE, BREACH OR FAILURE TO PERFORM BY
        GOODING OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR
        REPRESENTATIVES. IN NO EVENT SHALL GOODING OR ANY OF ITS DIRECTORS,
        OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES BE LIABLE FOR, AND EACH
        ATTENDEE, BIDDER AND BUYER HEREBY EXPRESSLY, KNOWINGLY AND INTENTIONALLY
        WAIVES AND AGREES THEY SHALL NOT SEEK ANY SPECIAL, INDIRECT, PUNITIVE,
        CONSEQUENTIAL OR INCIDENTAL DAMAGES OF ANY KIND ARISING OUT OF OR
        DIRECTLY OR INDIRECTLY RELATING IN ANY WAY TO THE AUCTION OR ANY LOT(S),
        INCLUDING ANY LOST PROFIT, APPRECIATION, LOSS OF OPPORTUNITY OR LOSS OF
        INVESTMENT, REGARDLESS OF THE ACTS OR OMISSIONS OR FAULT OF GOODING OR
        ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES.
      </p>
      <p>
        <em>No Liability for Repairs, Restoration or Replacement</em>. NEITHER
        GOODING NOR THE SELLER SHALL BE RESPONSIBLE OR LIABLE FOR ANY REPAIRS,
        RESTORATION, REPLACEMENT OF PARTS OR ANY OTHER MATTER, COST OR EXPENSE
        RELATING TO ANY LOT, WHETHER BEFORE OR AFTER THE AUCTION, AND BY
        PARTICIPATING IN THE AUCTION EACH BIDDER AND BUYER EXPRESSLY WAIVES, AND
        AGREES THAT IT WILL NOT SEEK ANY RECOVERY FROM OR PURSUE ANY CLAIM
        AGAINST GOODING OR THE SELLER, RELATING TO ANY OF THE FOREGOING,
        INCLUDING FOR REPAIRS, RESTORATION, OR REPLACEMENT OF PARTS. THIS NOTICE
        IS A PART OF THE GENERAL CONDITIONS AND IS NOT INTENDED TO LIMIT ANY OF
        THE OTHER TERMS OF SUCH GENERAL CONDITIONS.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="“AS IS” – NO WARRANTY">
      <p>
        <em>“As Is - Where Is.”</em> ALL SALES ARE “AS IS – WHERE IS” AND WITH
        ALL FAULTS. BIDDERS AND BUYERS ARE INVITED AND STRONGLY ENCOURAGED TO
        CONDUCT THEIR OWN INDEPENDENT INSPECTION AND EXAMINATION OF THE LOTS AND
        ARE SOLELY RESPONSIBLE FOR INSPECTING THE LOTS PRIOR TO BIDDING, AND
        SATISFYING THEMSELVES, AS TO THE CONDITION OF THE LOTS AND ALL OTHER
        MATTERS RELATING TO THE LOTS PRIOR TO BIDDING, INCLUDING ANY DESIRED OR
        NECESSARY REPAIRS, RESTORATION OR MISSING PARTS.
      </p>
      <p>
        <em>Disclaimer</em>. NOTWITHSTANDING ANY LOT DESCRIPTION, AUCTION
        ANNOUNCEMENT, AFFIRMATION, ADVERTISEMENT, PRESS RELEASE, CONDITION
        REPORT, STATEMENT, ASSURANCE, OR PROMISE (VERBAL OR WRITTEN) BY OR ON
        BEHALF OF GOODING OR ANY OF ITS EMPLOYEES, AGENTS OR REPRESENTATIVES,
        AND/OR SELLER, WHENEVER AND HOWEVER MADE, INCLUDING IN THE CATALOGUE,
        BILL OF SALE, TITLE DOCUMENTS, INVOICE OR OTHER WRITING, ALL SALES ARE
        MADE WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, AND NO LOT
        DESCRIPTION, AUCTION ANNOUNCEMENT, WRITTEN OR VERBAL INFORMATION,
        DESCRIPTION, POSTING, REFERENCE, ADVERTISEMENT, PRESS RELEASE, STATEMENT
        (VERBAL OR WRITTEN) BY OR ON BEHALF OF GOODING AND/OR SELLER, WHENEVER
        AND HOWEVER MADE, IS INTENDED TO BE, OR SHALL BE DEEMED TO BE OR DEEMED
        TO CREATE, A REPRESENTATION OR WARRANTY OR ASSUMPTION OF ANY LIABILITY
        BY GOODING OR SELLER. NEITHER GOODING NOR THE SELLER MAKES ANY
        REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY,
        AS TO TITLE WITH REGARD TO ANY LOT. FURTHER, REGARDLESS OF ANY
        ANNOUNCEMENT, STATEMENT, AFFIRMATION OR DESCRIPTION (WHETHER ORAL OR
        WRITTEN), NEITHER GOODING NOR THE SELLER MAKES ANY REPRESENTATION OR
        WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, AS TO
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, CORRECTNESS, ACCURACY
        OR ADEQUACY OF ANY DESCRIPTION (INCLUDING THE LOT DESCRIPTION),
        PERFORMANCE, ROADWORTHINESS, CONDITION, AUTHENTICITY, QUALITY,
        PROVENANCE, TITLE, RARITY OR HISTORICAL RELEVANCE WITH REGARD TO ANY LOT
        OR WHETHER OR NOT ANY LOT COMPLIES WITH ANY FEDERAL, STATE OR FOREIGN
        LAWS, REGULATIONS OR ORDINANCES OF ANY KIND, NOR DO GOODING OR THE
        SELLER ACCEPT OR ASSUME ANY LIABILITY OR RESPONSIBILITY FOR ANY OF THE
        FOREGOING. GOODING AND THE SELLER HEREBY EXPRESSLY DISCLAIM ANY AND ALL
        REPRESENTATIONS AND WARRANTIES, INCLUDING ANY EXPRESS, IMPLIED OR
        STATUTORY WARRANTIES, INCLUDING ANY REPRESENTATIONS OR WARRANTIES
        RELATING TO TITLE, WARRANTY FOR A PARTICULAR PURPOSE, MERCHANTABILITY,
        QUALITY, VALUE, ESTIMATES, ACCURACY OF INFORMATION OR DESCRIPTION AND
        ANY WARRANTY OF CONDITION OF THE LOT (INCLUDING AS TO ANY CONDITION
        REPORT).
      </p>
      <p>
        <em>Errors</em>. NEITHER GOODING NOR THE SELLER SHALL HAVE ANY LIABILITY
        OR RESPONSIBILITY FOR ANY ERRORS, OMISSIONS AND/OR INACCURACIES IN ANY
        DESCRIPTION, ANNOUNCEMENT, POSTINGS, STATEMENTS, DOCUMENTS OR MATERIALS,
        INCLUDING IN ANY PUBLISHED MATERIALS, REGARDLESS WHEN MADE. EACH BIDDER
        AND BUYER ACKNOWLEDGES AND AGREES THAT THEY ARE NOT ENTITLED TO RELY AND
        THEY EACH AGREE NOT TO RELY, UPON ANY OF SUCH ORAL OR WRITTEN
        DESCRIPTIONS, STATEMENTS, ANNOUNCEMENTS, INFORMATION OR MATERIALS OF ANY
        KIND FOR ANY PURPOSE.
      </p>
      <p>
        <em>Absentee</em>, Telephone and Online Bidding. WHILE GOODING ENDEAVORS
        TO PROVIDE A SAFE, SECURE, AND FUNCTIONING AUCTION, THERE IS NO
        GUARANTEE OF THE CONTINUOUS OPERATION OF OR ACCESS TO THE AUCTION OR A
        BIDDER’S ABILITY TO CONNECT AND NAVIGATE THE AUCTION. BID UPDATE AND
        OTHER NOTIFICATION FUNCTIONALITY IS SUBJECT TO DELAYS BEYOND GOODING’S
        CONTROL AND MAY NOT OCCUR IN REAL TIME. BIDDERS AGREE THAT THEY ARE
        MAKING USE OF ABSENTEE, TELEPHONE OR ONLINE BIDDING AT THEIR OWN RISK
        AND THAT SUCH FUNCTIONALITY IS BEING PROVIDED TO BIDDERS ON AN “AS
        AVAILABLE” AND “AS IS” BASIS WITHOUT ANY WARRANTY OR CONDITION, EXPRESS,
        IMPLIED OR STATUTORY. WITHOUT LIMITING THE FOREGOING, NEITHER GOODING
        NOR ANY OF ITS EMPLOYEES, DIRECTORS, OFFICERS, AGENTS OR REPRESENTATIVES
        SHALL HAVE ANY LIABILITY OR RESPONSIBILITY OF ANY KIND IF GOODING ELECTS
        OR IS UNABLE TO PROVIDE ABSENTEE, TELEPHONE OR ONLINE BIDDING, OR FOR
        ANY FAILURE TO RECEIVE OR EXECUTE SUCH BIDS FOR ANY REASON WHATSOEVER,
        INCLUDING ELECTRONIC, SOFTWARE, HARDWARE, TELECOMMUNICATION, INTERNET,
        CONNECTION, TRANSMISSION OR WI-FI FAILURES, DELAYS, DEFECTS, PROBLEMS OR
        ERRORS, OR FOR ANY ERRORS OR OMISSIONS IN CONNECTION THEREWITH, AND THE
        BIDDER ACCEPTS AND ASSUMES ALL RESPONSIBILITY AND LIABILITY FOR ANY
        ERRORS OR OMISSIONS RELATING THERETO OR FAILURE TO RECEIVE OR EXECUTE
        (INCLUDING TIMELY EXECUTION THEREOF) ANY BID REGARDLESS OF REASON,
        NEGLECT OR FAULT. GOODING DOES NOT REPRESENT OR WARRANT THAT ANY AUCTION
        PLATFORM, INCLUDING THE SITE, APP AND ONLINE BIDDING SYSTEM, WILL BE
        ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY
        DEFECTS WILL BE CORRECTED. GOODING RESERVES THE RIGHT TO SUSPEND OR
        TERMINATE ANY ASPECT OR FEATURE OF ONLINE BIDDING (INCLUDING ONLINE
        BIDDING IN ITS ENTIRETY) AT ANY TIME, WITH OR WITHOUT NOTICE. WITHOUT
        LIMITING THE FOREGOING, GOODING MAY SUSPEND ACCESS TO ONLINE BIDDING TO
        CARRY OUT SCHEDULED OR UNSCHEDULED MAINTENANCE OR FOR ANY REASON OR FOR
        NO REASON AT ANY TIME.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="MISCELLANEOUS">
      <p>
        Headings are for ease of reference only and may not be used to interpret
        the substance of the section to which they apply. All uses of the word
        “including,” “include” and “includes” shall not be limiting, and shall
        be deemed to be followed by “without limitation.” If any term,
        provision, condition or covenant of the General Conditions or its
        application to any party or circumstance shall be held, to any extent,
        invalid or unenforceable, the remainder of the General Conditions, or
        the application of the term, provision, condition or covenant to persons
        or circumstances other than those as to whom or which it is held invalid
        or unenforceable, shall not be affected, and shall be deemed modified to
        the minimum extent to be valid and enforceable to the fullest extent
        permitted by law. In the event of a conflict between these Conditions of
        Sale and any other document constituting a part of the General
        Conditions, the Conditions of Sale shall prevail unless the other
        conflicting provision is more strict against the Buyer or bidder. Time
        is of the essence for bidders’ and Buyer’s performance under the General
        Conditions.
      </p>
      <p>
        Except as otherwise expressly provided herein, no employee, officer,
        agent, representative or contractor of Gooding is authorized by Gooding
        to modify, waive or contradict any of the General Conditions. No act,
        omission, statement, promise, assurance or commitment of Gooding or any
        of its employees, officers, agents, representatives, or contractors
        shall be or be deemed to be, or operate as, a waiver or amendment of any
        of the General Conditions or of any of Gooding’s rights and remedies
        thereunder or constitute a representation or warranty of any kind and
        shall not have or be of any force or effect. Any statements, terms,
        conditions, representations or warranties relating to any Lot or the
        Auction not expressly set forth in the General Conditions shall not be,
        or be deemed to be, of any force or effect, regardless of how or when
        made.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="FOR ONLINE-ONLY AUCTIONS: EU DIRECTIVE ON CONSUMER RIGHTS">
      <p>
        If the Seller is a natural person or legal entity in the business of
        selling automobiles and acting for purposes related to such business (a
        “<strong>Business</strong>”) and the Buyer is a consumer that has a
        right of withdrawal against the Seller on the basis of EU consumer
        protection laws, then the Buyer may have an automatic statutory right to
        rescind the purchase of a Lot in an Online-Only Auction for 14 calendar
        days after the Buyer becomes responsible for taking possession of the
        Lot. Gooding reserves the right to make the reasonable determination as
        to whether a Seller meets the definition of “Business.” A Buyer who
        exercises the foregoing right to rescind is responsible for any and all
        expenses associated with the Lot, including transportation, duty,
        storage, insurance, and taxes. If the Buyer exercises this right, the
        Buyer bears all risk of loss, and is therefore advised to insure the
        Lot, until the Seller re-takes possession of the Lot.
      </p>
    </HeadLineWithParagraphs>
    <HeadLineWithParagraphs headline="FOR CALIFORNIA AUCTIONS: CALIFORNIA CAR BUYER’S BILL OF RIGHTS">
      <p>
        Pursuant to California law, Gooding offers any Buyer who purchases a
        used Vehicle for less than $40,000 at Auction the option to purchase a
        two-day cancellation option agreement, subject to statutory conditions.
        There is no cooling-off period unless the Buyer obtains a contract
        cancellation option agreement.
      </p>
    </HeadLineWithParagraphs>
  </div>
);
