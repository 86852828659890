export default (data) => {
return `
<div style="background:Array repeat" dir="">
<center style="padding:20px 0px 50px 0px">
  <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576backgroundTable">
    <tbody>
      <tr>
        <td align="center" valign="top">
          <table border="0" cellpadding="0" cellspacing="0" width="600" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576templateContainer" style="width:600px;max-width:100%">
            <tbody>
              <tr>
                <td id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576cellContainer" align="center" valign="top">
                  <div id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576divTemplateHeaderImage" style="background:Array;margin:0px 0px 20px 0px;border-top:0px solid #ffffff!important;border-bottom:0px solid #ffffff!important;border-left:0px solid #ffffff!important;border-right:0px solid #ffffff!important;border-radius:0px!important">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576templateHeaderImage">
                      <tbody>
                        <tr>
                          <td align="left" valign="top">
                            <div style="text-align:left;margin:px px px 25px">
                              <p style="margin:0px 0 0px 0"><img style="max-width:600px" alt="Gooding &amp; Company" src="https://ci4.googleusercontent.com/proxy/Xwvoi7DHrAZzXSgrSlUEP2CxVUqXYUbCb6AyrId_80zQld2EBosd3_O7-9HvA1Dp7gfuMGxdq_VQo_V0Nzs0EDmihByHdGd-DZnqkONC3IntjBRo5aCn566rDVOxXRP3ywWd5B6aRw=s0-d-e1-ft#http://d2f66jdfnuocai.cloudfront.net/wp-content/themes/kissponsive/images/logo/0.png" class="CToWUd"></p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576divTemplateHeader" style="background:#ffffff;font-family:Arial;font-weight:bold;vertical-align:middle;margin:0px 0px 0px 0px;border:none!important">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576templateHeader">
                      <tbody>
                        <tr>
                          <td align="left">
                            <div style="text-align:left;margin:24px 24px 24px 24px">
                              <h1 style="font:bold 26px Arial,sans-serif!important;color:#000000!important;margin:0;padding:0;text-align:left">New submission from Bidder Registration</h1>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576divTemplateBody" style="background:#ffffff;margin:0px 0px 0px 0px;border:none!important">
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576templateBody">
                      <tbody>
                        <tr>
                          <td valign="top" align="left">
                            <div style="color:#999999;margin:24px 24px 24px 24px">
                              <div>
                                <table border="0" cellpadding="0" cellspacing="0" width="552" style="width:552px;max-width:100%">
                                  <tbody>
                                    <tr>
                                      <td valign="top">
                                        <div style="color:#999999!important;font-family:Verdana,Geneva,sans-serif!important;font-size:14px!important;line-height:1.4em!important;font-weight:normal!important;font-style:normal!important">
                                          <table width="99%" border="0" cellpadding="1" cellspacing="0" bgcolor="#EAEAEA">
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <table width="100%" border="0" cellpadding="5" cellspacing="0" bgcolor="#FFFFFF">
                                                    <tbody>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Auction${data["Auctions Names"].length > 1 ? 's' : ''} ${data["Auctions Names"].length} Selected</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <ul>
                                                        ${data["Auctions Names"].map(auction => {
                                                          return `<li>${auction.name}</li>`
                                                        }).toString().replace(/,/g,"")}
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                    ${data["Auctions"].map((auction, i) => {
                                                      return `
                                                        <tr bgcolor="#EAF2FA">
                                                          <td colspan="2">
                                                            <font style="font-family:sans-serif;font-size:12px"><strong>Auction ${i+1}</strong></font>
                                                          </td>
                                                        </tr>
                                                        <tr bgcolor="#FFFFFF">
                                                          <td width="20">&nbsp;</td>
                                                          <td>
                                                            <font style="font-family:sans-serif;font-size:12px">${auction}</font>
                                                          </td>
                                                        </tr>
                                                      `
                                                    }).toString().replace(/,/g,"")}
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>First Name</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["First Name"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Last Name</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Last Name"]}</font>
                                                      </td>
                                                    </tr>
                                                    ${data["biddingMethod"] === "Telephone & Absentee Bidding" ?
                                                    `
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>primary Phone</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["primaryPhone"]}</font>
                                                      </td>
                                                    </tr>
                                                    ${data["secondaryPhone"] ?
                                                    `<tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Secondary Phone</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["secondaryPhone"]}</font>
                                                      </td>
                                                    </tr>`
                                                      :
                                                    ''
                                                      }
                                                    ${data["lots"].map((lot) => {
                                                      return `<tr bgcolor="#EAF2FA">
                                                        <td colspan="2">
                                                          <font style="font-family:sans-serif;font-size:12px"><strong>Lot Number</strong></font>
                                                        </td>
                                                      </tr>
                                                      <tr bgcolor="#FFFFFF">
                                                        <td width="20">&nbsp;</td>
                                                        <td>
                                                          <font style="font-family:sans-serif;font-size:12px">${lot['lotNumber']}</font>
                                                        </td>
                                                      </tr>
                                                      <tr bgcolor="#EAF2FA">
                                                        <td colspan="2">
                                                          <font style="font-family:sans-serif;font-size:12px"><strong>Description</strong></font>
                                                        </td>
                                                      </tr>
                                                      <tr bgcolor="#FFFFFF">
                                                        <td width="20">&nbsp;</td>
                                                        <td>
                                                          <font style="font-family:sans-serif;font-size:12px">${lot['description']}</font>
                                                        </td>
                                                      </tr>
                                                      <tr bgcolor="#EAF2FA">
                                                        <td colspan="2">
                                                          <font style="font-family:sans-serif;font-size:12px"><strong>Minimum Bid</strong></font>
                                                        </td>
                                                      </tr>
                                                      <tr bgcolor="#FFFFFF">
                                                        <td width="20">&nbsp;</td>
                                                        <td>
                                                          <font style="font-family:sans-serif;font-size:12px">${lot['maximumBid']}</font>
                                                        </td>
                                                      </tr>`
                                                    })}
                                                    `
                                                    :
                                                    ''
                                                    }
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>E-Mail</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px"><a href="mailto:${data["EMail"]}" target="_blank">${data["EMail"]}</a></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Address</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Address"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>City</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["City"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>State</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["State"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Postal Code</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Postal Code"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Country</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Country"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Phone</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Mobile Phone"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Bank Name &amp; Branch</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Bank Name & Branch"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Bank Street Address</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Bank Street Address"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Branch Telephone Number</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Branch Telephone Number"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Bank Postal Code</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Bank Postal Code"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Branch Officer / Account Officer</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Branch Officer / Account Officer"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Requested Bidding Limit</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Requested Bidding Limit"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Initials</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Initials"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Date Signed</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Date Signed"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Browser Details</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Browser Details"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Registration Total</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Registration Total"]}</font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#EAF2FA">
                                                      <td colspan="2">
                                                        <font style="font-family:sans-serif;font-size:12px"><strong>Status</strong></font>
                                                      </td>
                                                    </tr>
                                                    <tr bgcolor="#FFFFFF">
                                                      <td width="20">&nbsp;</td>
                                                      <td>
                                                        <font style="font-family:sans-serif;font-size:12px">${data["Status"]}</font>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576divTemplateFooter" style="background:#ffffff;margin:0px 0px 0px 0px;border:none!important">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576templateFooter">
                    <tbody>
                      <tr>
                        <td valign="middle" align="center" id="m_1659479758870219831m_-5711467945130001785m_2674962071088656576credit">
                          <div style="text-align:center;margin:24px 24px 24px 24px">
                            <div style="font:normal 11px Arial,sans-serif!important;color:#999999!important;text-align:center">
                              <p style="text-align:center;margin-top:0;padding:0">
                                <span></span>
                              </p>
                              <p style="text-align:left"><strong>Gooding &amp; Company</strong></p>
                              <p style="text-align:left">1517 20th Street<br>Santa Monica, CA 90404</p>
                              <p style="text-align:left"><a href="mailto:info@goodingco.com" target="_blank">info@goodingco.com</a></p>
                              <p style="text-align:left">Tel: 310.899.1960<a href="tel:3108991960" target="_blank"><br>
                              </a>Fax: 310.526.6594</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br>
        </td>
      </tr>
    </tbody>
  </table>
</center>
<div class="yj6qo"></div>
<div class="adL"></div>
</div>
`
}