import * as Yup from 'yup';
import { isValidDateOfBirth } from './dateOfBirthValidation';
import moment from 'moment';

const FILE_SIZE = 1000 * 1024;
const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
];

export function getRegisterToBidInitialValues(user) {
  return {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    dateOfBirth: user.birthdate
      ? moment(new Date(user.birthdate)).format('MM/DD/YYYY')
      : '',
    company: '',
    aboutYouAddress1: user.address1 || '',
    aboutYouAddress2: user.address2 || '',
    city: user.city || '',
    state: user.state || '',
    country: user.country || 'United States',
    zipcode: user.zipcode || '',
    phone: user.mainPhone || '',
    email: user.email || '',
    auction: [],
    biddingMethod: '',
    initials: '',
    photoIdentificationDocType: '',
    photoIdentificationFile: '',
    additionalIdentificationDocType: '',
    additionalIdentificationFile: '',
    bankLetterFile: '',
    bankName: '',
    bankStreetAddress: '',
    bankPhone: '',
    bankZipcode: '',
    bankBranchOffice: '',
    biddingLimits: '',
    acceptFinancialTerms: false,
    buyerStatus: user.buyerStatus || '',
    cardNumber: '',
    exp: '',
    cvc: '',
    billAddress: false,

    primaryPhone: '',
    secondaryPhone: '',
    lotNumber0: '',
    description0: '',
    maximumBid0: '',
    lotNumber1: '',
    description1: '',
    maximumBid1: '',

    // For Payment Form
    // firstName: user.firstName || '',
    // lastName: user.lastName || '',
    // company: '',
    bidderPrimaryAddress: '',
    bidderSecondaryAddress: '',
    // city: user.city1 || '',
    // state: '',
    // country: '',
    // zipcode: user.zipcode1 || '',
    // phone: user.mainPhone1 || '',

    firstName0: '',
    lastName0: '',
    company0: '',
    bidderPrimaryAddress0: '',
    bidderSecondaryAddress0: '',
    city0: '',
    state0: '',
    country0: '',
    zipcode0: '',
    phone0: '',
  };
}

const step1Schema = formType => {
  let schemaAttributes = {
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    aboutYouAddress1: Yup.string().required('Address 1 is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    country: Yup.string().required('Country is required.'),
    zipcode: Yup.string().required('Zipcode is required.'),
    phone: Yup.string().required('Phone is required').nullable(),
    email: Yup.string()
      .email('Please enter a valid email.')
      .required('Email is required.'),
    dateOfBirth: Yup.string()
      .required('Date of Birth is required.')
      .nullable()
      .test('valid', 'Please enter valid Date Of Birth', value =>
        isValidDateOfBirth(value)
      ),
  };
  return Yup.object().shape(schemaAttributes);
};

const step2Schema = formType => {
  let schemaAttributes = {
    auction: Yup.string().required('Please choose an Auction'),
    biddingMethod: Yup.string().required('Please choose a Bidding Method'),
  };
  return Yup.object().shape(schemaAttributes);
};

const step3Schema = formType => {
  let schemaAttributes = {
    initials: Yup.string()
      .min(2, 'Too Short!')
      .required('Please enter your initials to continue.')
      .test(
        'initials',
        'Initials Does not match first and last name provided',
        function (value) {
          const { firstName, lastName } = this.parent;
          if (value) {
            return (
              value.toUpperCase() ===
              getInitials(firstName, lastName).toUpperCase()
            );
          } else return false;
        }
      ),
  };
  return Yup.object().shape(schemaAttributes);
};

const step4Schema = formType => {
  let schemaAttributes = {
    photoIdentificationDocType: Yup.string().required(
      'Document Type is required.'
    ),
    photoIdentificationFile: Yup.mixed()
      .required('A file is required.')
      .test(
        'fileSize',
        'File too large.',
        value => value && value.size <= FILE_SIZE
      )
      .test(
        'fileFormat',
        'Unsupported Format.',
        value => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    additionalIdentificationFile: Yup.mixed()
      .test('fileSize', 'File too large.', value =>
        value ? value.size <= FILE_SIZE : true
      )
      .test('fileFormat', 'Unsupported Format.', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      ),
  };
  return Yup.object().shape(schemaAttributes);
};

const step5Schema = formType => {
  let schemaAttributes = {
    bankLetterFile: Yup.mixed()
      .required('A file is required.')
      .test('fileSize', 'File too large.', value =>
        value ? value.size <= FILE_SIZE : true
      )
      .test('fileFormat', 'Unsupported Format.', value =>
        value ? SUPPORTED_FORMATS.includes(value.type) : true
      ),
    bankName: Yup.string().required('Bank Name is required.'),
    biddingLimits: Yup.string().required(
      'Requested Bidding Limits is required.'
    ),
    acceptFinancialTerms: Yup.boolean().oneOf(
      [true],
      'Please Accept Terms and Conditions'
    ),
  };
  return Yup.object().shape(schemaAttributes);
};
const step6Schema = formType => {
  let schemaAttributes;

  if (formType === 'Telephone') {
    schemaAttributes = {
      primaryPhone: Yup.string().required('Phone is required').nullable(),
      description0: Yup.string().required('Description is required.'),
    };
  } else {
    schemaAttributes = {
      firstName0: Yup.string().required('First Name is required.'),
      lastName0: Yup.string().required('Last Name is required.'),
      company0: Yup.string().required('Company Name is required.'),
      bidderPrimaryAddress0: Yup.string().required('Address 1 is required.'),
      city0: Yup.string().required('City is required.'),
      state0: Yup.string().required('State is required.'),
      country0: Yup.string().required('Country is required.'),
      zipcode0: Yup.string().required('Zipcode is required.'),
      phone0: Yup.string().required('Phone is required').nullable(),
      email: Yup.string()
        .email('Please enter a valid email.')
        .required('Email is required.'),
      cardNumber: Yup.string()
        .min(12, 'Too Short!')
        .required('Card number is required'),
      exp: Yup.string()
        .typeError('Not a valid. Example: MM/YY')
        .max(5, 'Not a valid. Example: MM/YY')
        .matches(/([0-9]{2})\/([0-9]{2})/, 'Not a valid. Example: MM/YY')
        .required('Expiration date is required'),
      cvc: Yup.string().min(4, 'Too Short!').required('Please enter your CVC.'),
    };
  }
  return Yup.object().shape(schemaAttributes);
};

export function getRegisterToBidSchema() {
  return {
    1: step1Schema,
    2: step2Schema,
    3: step3Schema,
    4: step4Schema,
    5: step5Schema,
    6: step6Schema,
  };
}

const getInitials = (firstName, lastName) => {
  return `${firstName.match(/\b(\w)/g).join('')}${lastName
    .match(/\b(\w)/g)
    .join('')}`;
};
