import React from 'react';
import PropTypes from 'prop-types';
import { textPropType } from 'shared/propTypes';

const ItemList = ({ data, handleSelect }) => (
  <div key={data.id}>
    <div>
      <p className="sec">
        <span className="text-subdued">{data.subTitle}</span>
        <span>{data.cost}</span>
      </p>
      <p className="text-subdued">{data.date}</p>
    </div>
    <h6
      className="remove-item"
      onClick={() => handleSelect(data.id)}
      role="presentation"
    >
      Remove Item
    </h6>
  </div>
);
ItemList.propTypes = {
  handleSelect: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    cost: PropTypes.string,
    date: textPropType,
  }),
};
export default ItemList;
