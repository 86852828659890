import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import Login from 'components/Auth/login';
import Unverified from 'features/Auth/unverified';
import OnlineRegistration from 'privatePages/OnlineRegistration';
import Following from 'privatePages/Following';
import Profile from 'privatePages/Profile';

const App = () => (
  <Router>
    <PrivateRoute
      path="/auth/online-registration"
      component={OnlineRegistration}
    />
    <PrivateRoute path="/auth/following" component={Following} />
    <PrivateRoute path="/auth/profile" component={Profile} />
    <Unverified path="/auth/unverified" />
    <Login path="/auth/login" />
  </Router>
);
export default App;
