import { parsePhoneNumberFromString } from 'libphonenumber-js';
import csc from 'country-state-city';

export const handleVerifyUser = async (props, verifyUser) => {
  const countryData = csc
    .getAllCountries()
    .filter(country => country.name === props.values.country);
  let verifyUserResp;
  try {
    verifyUserResp = await verifyUser({
      variables: {
        verifiableData: {
          firstName: props.values.firstName,
          lastName: props.values.lastName,
          streetAddress1: props.values.aboutYouAddress1,
          streetAddress2: props.values.aboutYouAddress2,
          city: props.values.city,
          state: props.values.state,
          zipcode: props.values.zipcode,
          phone: parsePhoneNumberFromString(props.values.phone, 'US').number,
          country: countryData.sortname,
          email: props.values.email,
        },
      },
    }).then(
      resp => resp.data?.verifyRegisterToBidData?.verificationStatus || 'Error'
    );
  } catch (err) {
    verifyUserResp = { verificationStatus: 'Error' };
  }
  return verifyUserResp;
};
