import React from 'react';
import {
  getAuction,
  getPrice,
  getTag,
  getAlgoliaItemTitle,
} from 'shared/helpers/vehicles';
import { generateFluidImage } from 'utils/image';
import VehicleCard from 'components/VehicleCard';
import styles from './list.module.scss';

const List = ({ vehicles = [], currencies }) => {
  return (
    <div className={styles.vehiclesListContainer}>
      <div className={styles.vehiclesList}>
        {vehicles.map((vehicle, index) => (
          <VehicleCard
            key={`vehicleCard_${index}`}
            fluidImages={
              !!vehicle.images.length
                ? vehicle.images.map(image => {
                    const width = image.file?.details?.image?.width;
                    const height = image.file?.details?.image?.height;
                    return {
                      fluid: generateFluidImage(
                        332,
                        image.file.url,
                        90,
                        width,
                        height
                      ),
                    };
                  })
                : null
            }
            tag={getTag(vehicle)}
            makeModel={getAlgoliaItemTitle(vehicle)}
            auction={getAuction(vehicle, true)}
            price={getPrice(vehicle, currencies[vehicle.venueId])}
            lotNumber={vehicle.lotNumber}
            slug={vehicle.slug}
            showDotsOnHover={true}
            salesForceId={vehicle?.salesForceId}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
