import { gql } from '@apollo/client';

export const GET_REGISTER_TO_BID_DATA = gql`
  query GetRegisterToBiData {
    profile {
      id
      firstName
      lastName
      birthdate
      email
      secondaryEmail
      mainPhone
      mobilePhone
      address1
      address2
      city
      state
      zipcode
      country
      financialDocumentSubmissionDate
      privacyPolicyAcceptanceDate
      buyerStatus
    }
    getAuctions {
      objectID
      date
      eventStatus
      name
      image
      eventAttendanceMode
      offers {
        id
        category
        price
        priceCurrency
        acceptedPaymentMethod
        includesObject
        eligibleQuantity
        eligibleCustomerType {
          id
          title
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($subject: String, $body: String, $attachment: [String]) {
    SendEmail(subject: $subject, body: $body, attachment: $attachment) {
      code
      errorMessages
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyRegisterToBidData($verifiableData: VerifiableData!) {
    verifyRegisterToBidData(verifiableData: $verifiableData) {
      verificationStatus
    }
  }
`;

export const GET_AUCTION_LOTS = gql`
  query getAuctionVehicles(
    $auctionIds: String
  ) {
    getAuctionVehicles (auctionIds: $auctionIds){
      objectID
      lotNumber
      auctionId
      title
      make
      model
      modelYear
    }
  }
`;
