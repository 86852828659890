import React from 'react';
import FloatingLabel from 'floating-label-react';
import NumberFormat from 'react-number-format';
import { Field } from 'formik';

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';
      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }
  return val;
};

const cardExpiry = val => {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 4);
  return month + (year.length ? '/' + year : '');
};

export default ({ name, showErrorMessages = true }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div className="app-input-text">
        <NumberFormat
          customInput={FloatingLabel}
          name={field.name}
          className={`${meta.touched && meta.error ? 'error' : ''}`}
          format={cardExpiry}
          placeholder="Exp MM/YY&#42;"
          {...field}
        />
        {meta.touched && meta.error && showErrorMessages && (
          <p className="error-message">{meta.error}</p>
        )}
      </div>
    )}
  </Field>
);
