const OPTIONS = [
  {
    value: 'passport',
    label: 'Passport',
  },
  {
    value: 'drivers_license',
    label: "Driver's License",
  },
  {
    value: 'dealers_license',
    label: 'Dealer’s License',
  },
  {
    value: 'sellers_permit',
    label: "Seller's Permit",
  },
];

const P1 = `Please upload all applicable documents, such as driver's license,
passport, dealer license, or seller's permit. At a minimum, all bidders
will be required to submit one form of identification before they will
be issued bidder credentials.`;

const P2 = `Simply click the "Choose File" and select an image to upload. We accept
.PNG, .JPG, and .PDF file types only. We will never release these
documents to any third party without your express consent.`;

export { OPTIONS, P1, P2 };
