import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Loading from 'components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';

const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    loginWithRedirect({
      appState: { targetUrl: '/' },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) navigate('/');
  return <Loading />;
};

export default Login;
